import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseStatus from '../../components/BaseStatus/BaseStatus'
import { terminate } from '../../store/Auth/AuthAction';
import constant from '../../utils/Constants';
import AcknowledgementFailed from '../Status/components/Failed/AcknowledgementFailed';


const NotFound = (props) => {
    const token = useSelector(state => state.token)
    const payment = useSelector(state => state.payment)
    const [redirect, setRedirect] = useState(false)
    const [redirectUrl, setRedirectUrl] = useState('')
    const [endPoint, setEndpoint] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (token.isAuth) {
            dispatch(terminate());
        }

        if (payment.data) {
            let url = payment.data.redirectUrl.cancel
            if (payment.data.status !== 'PENDING') {
                url = payment.data.status === 'SUCCESS' ? payment.data.redirectUrl.success : payment.data.redirectUrl.failure
            }
            setRedirectUrl(url)
            setRedirect(true)
        } else {
            setEndpoint(true)
        }

    }, [payment])

    return (
        <div>
            {
                !endPoint ?
                    <AcknowledgementFailed
                        status={"404"}
                        expiredSession={true}
                        title={constant.status_404_title}
                        body={constant.status_404_body}
                        merchantLink={redirectUrl}
                        redirect={redirect}
                        redirectLabel={"Okay, bring me back"}
                    />
                    : <BaseStatus
                        status={"404"}
                        title={'404 not found...'}
                        body={'Sorry, but the page you\'re trying to access was not found.'}
                    />
            }

        </div>
    );
}

export default NotFound;