import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Typography, Grid, IconButton, withStyles } from '@material-ui/core';
import { TextField, Checkbox }  from '@mui/material';
import questionLogo from '../../components/assets/questionLogo.png';
import constants from '../../utils/Constants';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { getLocalPaymentId } from '../../utils/Auth';
import { updatePayment } from '../../store/Payment/PaymentAction';

const useStyles = makeStyles((theme) => ({

    container: {
        marginTop: 0,
        marginBottom: 30,
        marginLeft: 60,
        marginRight: 60,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 20,
            marginRight: 20,
        },
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    centerTitle: {
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
    },

    actionContainer: {
        paddingTop: 25,
        paddingBottom: 25,
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
    },

    dialogBody: {
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },

    dialogText: {
        color: '#47494B',
        font: 'normal normal normal 14px/21px Poppins',
        fontSize: 14,
        fontWeight: 200,
        letterSpacing: '0.69px',
    },

    titleText: {
        marginTop: 20,
        fontSize: 20,
        fontWeight: 'none',
        letterSpacing: '1.08px',
        color: '#335D72',
    },

    optionContainer: {
        marginLeft: 50,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    option: {
        display: 'flex',
        marginTop: 'auto',
        marginBottom: 'auto',
    },

    optionCheckbox: {
        [theme.breakpoints.down("sm")]: {
            margin: 0,
        },
        [theme.breakpoints.down("xs")]: {
            margin: 0,
        },
    },

    optionLabel: {
        color: '#5E5E5E',
        font: 'normal normal normal 12px/30px Poppins',
        fontSize: 13,
        textAlign: 'left',
        marginLeft: '0.3em',
        letterSpacing: '0.59px',
        opacity: 1,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 5,
            lineHeight: 1.5,
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: 5,
            lineHeight: 1.5
        },
    },

    reasonField: {
        marginTop: '1%',
        marginBottom: 'auto',
        marginLeft: '13%',
        [theme.breakpoints.down("xs")]: {
            marginTop: '2%',
            marginLeft: 0,
        },
    },

    reasonTextArea: {
        width: '85%',
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        },
    },

    buttonAction: {
        letterSpacing: '0.59px',
        font: 'normal normal medium 12px/18px Poppins',
        fontSize: 12,
        width: '129px',
        height: '42px',
        borderRadius: '21px',
        textTransform: 'none',
        marginLeft: 10,
        marginRight: 10,
    },

    submitButtonText: {
        color: "white"
    },

}));

const ExitSurveyModal = props => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { clickCloseOutside = false } = props
    const [showReasonField, setShowReasonField] = useState(false)
    const [reason, setReason] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [answers, setAnswers] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
    })

    const options = {
        1: constants.survey_option1,
        2: constants.survey_option2,
        3: constants.survey_option3,
        4: constants.survey_option4,
    }

    const SubmitButton = withStyles((theme) => ({
        root: {
            backgroundColor: "#0076BF",
            '&:hover': {
                backgroundColor: "#0287d9",
            },
            "&:disabled": {
                backgroundColor: "#969696"
            },
            letterSpacing: '0.59px',
            font: 'normal normal medium 12px/18px Poppins',
            fontSize: 12,
            width: '129px',
            height: '42px',
            borderRadius: '21px',
            textTransform: 'none',
            marginLeft: 10,
            marginRight: 10,
        },
    }))(Button);

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;
      
        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        className={classes.closeButton}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
          </DialogTitle>
        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const handleCloseOnClickOutside = () => {
        if (clickCloseOutside) props.setSurveyModalOpen(false)
    }

    const handleClose = () => {
        props.setSurveyModalOpen(false)
        setShowReasonField(false)
        setDisableSubmit(true)
        setReason('')
        setAnswers({
            1: false,
            2: false,
            3: false,
            4: false,
        })
    }

    const handleChange = (event) => {
        setAnswers({
            ...answers,
            [event.target.name]: event.target.checked,
        })
        if (event.target.name === '4' && event.target.checked) {
            setShowReasonField(true)
        } else if (event.target.name === '4' && !event.target.checked) {
            setShowReasonField(false)
            setReason('')
        }
    }

    const handleSubmit = () => {
        let surveyAnswers = {}
        for (let entry of Object.keys(answers)) {
            if (answers[entry]) {
                surveyAnswers[entry] = entry === '4' ? reason : options[entry]
            }
        }
        dispatch(updatePayment({ paymentId: getLocalPaymentId(), surveyAnswers: surveyAnswers }, constants.payment_canceled))
    }

    useEffect(() => {
        let hasSelected = false
        for (let entry of Object.keys(answers)) {
            if (entry !== '4' && answers[entry]) {
                hasSelected = true
            } else if (entry === '4' && answers[entry]) {
                let onlySpaces = (/^\s*$/).test(reason)
                hasSelected = onlySpaces ? false : true
            }
        }
        hasSelected ? setDisableSubmit(false) : setDisableSubmit(true)
    }, [answers, reason])

    return (
        <div>
            <Dialog
                id='dialog-survey'
                onClose={handleCloseOnClickOutside}
                open={props.open}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
                <Grid id='grid-container' className={classes.container}>
                    <DialogTitle className={classes.centerTitle} id="scroll-dialog-title">
                        <img src={questionLogo}></img>
                        <Typography id= 'typography-title' variant='h2' className={classes.titleText}>{constants.survey_header}</Typography>
                    </DialogTitle>
                    <DialogContent id='dialog-content-survey' className={classes.dialogBody}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography id='typography-body' variant='h3' className={classes.dialogText}>
                                {constants.survey_body}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <Grid
                        container
                        id='grid-container-option'
                        direction='column'
                        className={classes.optionContainer}
                    >
                        {
                            options && Object.keys(options).map((key, i) => {
                                return (
                                    <Grid item id='grid-item-option' className={classes.option}>
                                        <Grid item id='grid-item-option-checkbox' xs={2} sm={1} md={1} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                            <Checkbox id='checkbox-option' className={classes.optionCheckbox} size='small' checked={answers[key]} onChange={handleChange} name={key} />
                                        </Grid>
                                        <Grid item id='grid-item-option-label' xs={10} sm={11} md={11} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                            <Typography id='typography-option' className={classes.optionLabel}>{options[key]}</Typography>           
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        showReasonField ? 
                            <Grid item id='grid-item-reason' className={classes.reasonField}>
                                <TextField
                                    id='filled-multiline-static'
                                    multiline
                                    rows={2}
                                    variant='outlined'
                                    inputProps={{
                                        maxLength: 200,
                                    }}
                                    InputProps={{
                                        style : {
                                            color: '#5E5E5E',
                                            font: 'normal normal normal 12px/30px Poppins',
                                            fontSize: 12,
                                            letterSpacing: '0.59px',
                                            opacity: 1,
                                        }
                                    }}
                                    onChange={(e) => setReason(e.target.value)}
                                    className={classes.reasonTextArea}
                                />
                            </Grid> 
                        : <></>
                    }
                    <DialogActions id='dialog-actions-survey' className={classes.actionContainer}>
                        <Button id='button-skip' variant='outlined' color="primary" className={classes.buttonAction} fullWidth='false' onClick={props.handleYesModal}>
                            {constants.survey_skip_text}
                        </Button>
                        <SubmitButton id='button-submit' disabled={disableSubmit} variant='contained' fullWidth='false' onClick={handleSubmit}>
                            <div className={classes.submitButtonText}>{constants.survey_submit_text}</div>
                        </SubmitButton>
                    </DialogActions>
                </Grid>
            </Dialog>
        </div>
    );
};


export default ExitSurveyModal;