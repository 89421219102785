import {
    Button,
    CircularProgress,
    Typography,
    makeStyles,
    withStyles
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
    loader: {
        color: '#fafafa'
    },
    currency: {
        verticalAlign: "top",
        fontSize: 11,
        marginRight: 3,
        marginLeft: 10,
        [theme.breakpoints.down("xs")]: {
            fontSize: 7,
        },
    },
    pay: {
        color: "white"
    }
}))

const PayButton = (props) => {
    const classes = useStyles()

    const PayButtonElement = withStyles((theme) => ({
        root: {
            backgroundColor: "#0076BF",
            '&:hover': {
                backgroundColor: "#0287d9",
            },
            "&:disabled": {
                backgroundColor: "#9d9d9d"
            },
            margin: 'auto',
            padding: 'auto',
            display: "flex",
            alignSelf: "center",
            // width: theme.spacing(50),
            height: 65,
            borderRadius: 30,
            [theme.breakpoints.down("xs")]: {
                height: 50,
            },
        },
    }))(Button);

    return (
        <PayButtonElement
            variant="contained"
            onClick={props.handleSubmit}
            fullWidth={true}
            disabled={props.disabled}
        >
            {
                props.isLoading && props.isLoading ?
                    <CircularProgress color="inherit" size={30} className={classes.loader} /> :
                    <Typography variant="button">
                        <Typography variant="h6" sx={{ color: "white" }}>
                            <NumberFormat
                                value={props.totalAmount}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value, props) =>
                                    <div className={classes.pay}>
                                        Pay
                                        <a className={classes.currency}>PHP</a>
                                        {value}

                                    </div>


                                }
                            />
                        </Typography>
                    </Typography>
            }
        </PayButtonElement>
    )

}

export default PayButton