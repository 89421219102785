import processActions from '../../utils/ProcessActions'
import { GET_AUTH, GET_AUTH_FAILED, GET_AUTH_SUCCESS, GET_PAYMENT_ID } from './AuthTypes'
import { login as loginService } from './AuthServices'
import { all, put, select, take, takeEvery } from '@redux-saga/core/effects';
import * as selectors from '../selectors'
import { getPayment } from '../Payment/PaymentAction';
import { GET_PAYMENT, UPDATE_PAYMENT } from '../Payment/PaymentTypes';
import history from '../../utils/history';

function* login({ payload }) {
    yield processActions({
        params: payload.paymentId,
        service: loginService,
        success: GET_AUTH_SUCCESS,
        failed: GET_AUTH_FAILED
    })
    yield put({
        type: GET_PAYMENT_ID,
        payload: payload.paymentId
    })

    let isAuth = yield (select(selectors.isAuth))
    console.log(isAuth && payload.executeUpdate)
    if (isAuth && !payload.justAuth) {
        yield put({
            type: GET_PAYMENT,
            payload
        })
    } else if (isAuth && payload.executeUpdate) {
        yield put({
            type: UPDATE_PAYMENT,
            data: {
                ...payload.updateData,
            }
        })
    }
}

function authFailed() {
    history.push('/404')
}


export default function* watchAuthRequests() {
    yield all([
        takeEvery(GET_AUTH, login),
        takeEvery(GET_AUTH_FAILED, authFailed)
    ])
}