import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, Typography } from '@material-ui/core';
import constants, { constantFormat } from '../../utils/Constants';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({

    actionContainer: {
        paddingTop: 20,
        paddingBottom: 30,
        margin: 'auto',
        justifyContent: 'center',
        paddingTop: 0
    },

    info: {
        paddingTop: 20,
    },

    btnAction1: {
        marginRight: 20,
        fontSize: 15,
        backgroundColor: "#f5f5f5",
        color: "#414141"
    },

    btnAction2: {
        marginLeft: 20,
        fontSize: 15,
        textTransform: 'none'
    },

    dialogBody: {
        marginTop: 20,
        textAlign: 'left',
    },

    dialogText1: {
        fontSize: 15,
        color: '#181818',
        fontWeight: 'bold',
        textAlign: 'left',
        letterSpacing: 2,
    },

    dialogText2: {
        fontSize: 15,
        color: '#181818',
        fontWeight: 200,
        textAlign: 'left',
        letterSpacing: 1,
    },
    dialogTextDate: {
        fontSize: 15,
        color: '#181818',
        fontWeight: 200,
        textAlign: 'left',
        letterSpacing: 1,
        paddingBottom: 20,
    },

    dialogConfirm: {
        textAlign: "center"
    }

}));
const DuplicatePromptModal = props => {
    const duplicateData = useSelector(state => state.duplicateData)
    const [items, setItems] = useState([]);
    const [merchantBtn, setMerchantBtn] = useState('')
    const [merchantHeader, setMerchantHeader] = useState('')


    useEffect(() => {
        if (duplicateData) {
            const paymentItems = [...duplicateData.body.map(content => {
                return {
                    date: constantFormat({
                        date: moment(content.datetime).format('LLL')
                    }, constants.duplicate_prompt_date),
                    amount: constantFormat({
                        amount: content.amount
                    }, constants.duplicate_prompt_amount),
                    billerAndAccount: constantFormat({
                        merchant: content.biller,
                        accountNumber: content.can
                    }, constants.duplicate_prompt_account)
                }
            })]
            setItems(paymentItems)
            setMerchantBtn(
                constantFormat({
                    merchant: duplicateData.merchant
                }, constants.duplicate_prompt_back))
            setMerchantHeader(duplicateData.header)
        }
    }, [duplicateData])


    const classes = useStyles()
    const { open, handleButtonModal1, handleButtonModal2 } = props
    return (
        <div>
            <Dialog
                open={open}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth="true"
                maxWidth="sm"
            >
                <DialogContent className={classes.dialogBody}>
                    <DialogContentText component={'div'} id="scroll-dialog-description" tabIndex={-1}>
                        <Typography variant='h3' className={classes.dialogText1}>
                            {merchantHeader}
                            <br />
                        </Typography>
                    </DialogContentText>
                    <DialogContentText component={'div'} className={classes.info}
                        id="scroll-dialog-description" tabIndex={-1}>
                        {
                            items.map((details, i) => {
                                return (
                                    <>
                                        <Typography variant='h3' className={classes.dialogText2}>
                                            {details.billerAndAccount}
                                        </Typography>
                                        <Typography variant='h3' className={classes.dialogText2}>
                                            {details.amount}
                                        </Typography>
                                        <Typography variant='h3' className={classes.dialogTextDate}>
                                            {details.date}
                                        </Typography>
                                    </>
                                )
                            })
                        }
                    </DialogContentText>
                    <DialogContentText component={'div'} id="scroll-dialog-description" tabIndex={-1} >
                        <Typography variant='h3' className={`${classes.dialogText1} ${classes.dialogConfirm}`}>
                            {constants.duplicate_prompt_proceed}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <Button variant='contained' className={classes.btnAction1} onClick={handleButtonModal2}>
                        {constants.duplicate_prompt_back}
                    </Button>
                    <Button variant='contained' className={classes.btnAction2} color="primary" onClick={handleButtonModal1}>
                        {constants.duplicate_prompt_confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default DuplicatePromptModal;