import React, { useState } from 'react';
import constants from '../../utils/Constants';
import {
    Typography,
    Grid,
    Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import DotLoader from '../../components/DotLoader/DotLoader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestAuth } from '../../store/Auth/AuthAction';
import { getLocalPaymentId, getSession, setSession, storePaymentId } from '../../utils/Auth';
import { useLocation } from "react-router-dom";
import bayadLogo from "../../assets/icons/bayadLogo.svg"
import { mpgsPay, mpgsProcess } from '../../store/Pgw/PgwAction';
import { getPayment, getProcessor } from '../../store/Payment/PaymentAction';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        margin: "auto",
        textAlign: 'center',
        backgroundColor: "#dcf0fa"
    },

    spiel: {
        marginTop: 50
    },

    cardBody: {
        marginTop: 15,
        fontSize: 14,
        lineHeight: 1.5
    },

    title: {
        fontSize: 18,
        letterSpacing: 3,
        fontWeight: "bold",
        lineHeight: 1.5
    },
}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const MpgsProcess = props => {
    const classes = useStyles();
    const query = useQuery();
    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.payment)
    const [paymentFetch, setPaymentFetch] = useState(false)
    const [processorFetch, setProcessorFetch] = useState(false)
    const history = useHistory()
    const [isBackButtonClicked, setBackButtonClicked] = useState(false)

    useEffect(() => {
        dispatch(requestAuth({ paymentId: getLocalPaymentId(), isUpdate: true, checkDuplicate: false }))
    }, [])

    useEffect(() => {
        if (paymentData.data && !paymentFetch) {
            let cardScheme = ''
            switch (query.get('cardScheme')) {
                case 'MASTERCARD':
                    cardScheme = "Mastercard"
                    break
                case 'VISA':
                    cardScheme = "Visa"
                    break
                default:
            }
            dispatch(getProcessor(paymentData.data.totalAmount.value, 'card', cardScheme, getLocalPaymentId()))
            setPaymentFetch(true)
        }
    }, [paymentData])




    useEffect(() => {
        if (paymentData.data) {
            if (paymentData.data.pgwStatus === "FAILED") {
                console.log(paymentData.data.pgwStatus)
                history.push('/status/failed')
            }
        }
    }, [paymentData])


    useEffect(() => {
        if (paymentFetch && !processorFetch) {
            dispatch(mpgsProcess(query.get('paymentSessionId'), getLocalPaymentId()))
            setProcessorFetch(true)
        }
    })

    return (
        <div>
            <Grid container className={classes.root}>

                <Grid container item direction='column' justify='center' alignItems='center' className={classes.spiel} >
                    <Grid item>
                        <Typography className={classes.title} >{constants.loader_title}</Typography>
                    </Grid>
                    <Grid item>
                        <DotLoader />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default MpgsProcess;