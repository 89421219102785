import { TERMINATE_SESSION } from '../Auth/AuthTypes'
import * as types from './PgwTypes'

const INITIAL_STATE = {
    data: null,
    isLoading: false,
    session: null,
    mpgsRedirect: null,
    error: {
        flag: false,
        data: ''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PGW_MPGS_CREATE_SESSION:
            return {
                ...state,
                // isLoading: true,
                error: {
                    flag: false,
                    data: ''
                }
            }
        case types.PGW_TOKEN_PAYMAYA:
            return {
                ...state,
                isLoading: true,
                error: {
                    flag: false,
                    data: ''
                }
            }
        case types.PGW_TOKEN_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case types.PGW_TOKEN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: true,
                    data: action.error.response.data
                }
            }
        case types.PGW_PAYMAYA_CREATE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        case types.PGW_PAYMAYA_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: true,
                    data: action.error.response.data
                }
            }
        case types.PGW_MPGS_CREATE_SESSION_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case types.PGW_MPGS_INITIATE_AUTHENTICATION_SUCCESS:
        case types.PGW_MPGS_AUTHENTICATE_SUCCESS:
            return {
                ...state,
                error: {
                    flag: false,
                    data: null
                }
            }
        case types.PGW_MPGS_PAY_FAILED:
        case types.PGW_MPGS_INITIATE_AUTHENTICATION_FAILED:
        case types.PGW_MPGS_AUTHENTICATE_FAILED:
        case types.PGW_MPGS_UPDATE_FORM_FAILED:
            return {
                isLoading: false,
                error: {
                    flag: true,
                    data: action.error.response.data
                }
            }
        case types.PGW_MPGS_PAY_SUCCESS:
            return {
                ...state,
                error: {
                    flag: false,
                    data: null
                }
            }
        case TERMINATE_SESSION:
            return INITIAL_STATE
        default:
            return state
    }
}