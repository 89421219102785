import React from 'react';
import constants from '../../utils/Constants';
import {
    Typography,
    Grid,
    Container,
    Popover,
    Button,
    useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DotLoader from '../../components/DotLoader/DotLoader';
import Header from '../../components/Header/Header';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkoutPaymentId, requestAuth } from '../../store/Auth/AuthAction';
import { getSession, setSession, getLocalPaymentId } from '../../utils/Auth';
import { useLocation, Link } from "react-router-dom";
import loadingImage from '../../assets/loading.png';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '90vh',
        margin: "auto",
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {
            padding: 10,
        },
    },

    cardBody: {
        color: 'gray',
        marginTop: 15,
        fontSize: 14,
        lineHeight: 1.5,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
        },
    },

    title: {
        color: 'gray',
        fontSize: 16,
        letterSpacing: 1,
        fontWeight: "bold",
        lineHeight: 1.5,
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Loader = props => {
    const classes = useStyles();
    const query = useQuery();
    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.payment.data)
    const paymentErr = useSelector(state => state.payment.error)
    const token = useSelector(state => state.token)
    const auth = useSelector(state => state.auth)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    const { getAuth = true, loader_body, loader_title } = props
    useEffect(() => {
        if (!paymentData && !auth.isLoading && !auth.isAuth && getAuth) {
            dispatch(checkoutPaymentId(query.get('paymentId')))
            dispatch(requestAuth({ paymentId: query.get('paymentId'), checkDuplicate: true }));
        }
    }, []);

    //useEffect(() => {
    //    if (paymentErr.flag) {
    //        props.history.push(`/404`)
    //    }
    //}, [paymentErr])

    return (
        <div>
            {
                !matches ? <Header/> : <></>
            }
            <Grid container className={classes.root}>

                <Grid container item direction='column' justify='center' alignItems='center'>
                    <Grid item>
                        <img src={loadingImage} style={{ verticalAlign: 'middle', marginBottom: -50 }}></img>
                        <DotLoader />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} >{getAuth ? constants.loader_title : props.loader_title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.cardBody} color="textSecondary">{getAuth ? constants.loader_body : props.loader_body}</Typography>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};

export default Loader;