import { Button, Container, Grid, makeStyles, Typography, withStyles, Radio, useMediaQuery } from '@material-ui/core';
import { createTheme, MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import urlEncode from '../../../../utils/UrlEncode';
import { redirectUrl } from '../../../../store/Auth/AuthAction';

const useStyles = makeStyles((theme) => ({
  eWalletContainer: {
    paddingLeft: 0, 
    paddingRight: 0,
  },
  eWalletItem: {
    display: 'flex',
    paddingTop: 7,
    paddingBottom: 7,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 3,
      paddingBottom: 3,
    },
  },
  eWalletRadioButtonContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  eWalletLabelContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  eWalletLabel: {
      textAlign: 'left',
      marginLeft: '1em',
      fontSize: '1.1em',
      fontWeight: 'bold',
      letterSpacing: '0.59px',
      color: 'gray',
      [theme.breakpoints.down("md")]: {
          marginLeft: '0.5em',
      },
      [theme.breakpoints.down("xs")]: {
          marginLeft: '0.25em',
      },
  },
  eWalletLabelDisabled: {
      textAlign: 'left',
      marginLeft: '1em',
      fontSize: '1.1em',
      fontWeight: 'bold',
      letterSpacing: '0.59px',
      color: '#b1b1b1',
      [theme.breakpoints.down("md")]: {
          marginLeft: '0.5em',
      },
      [theme.breakpoints.down("xs")]: {
          marginLeft: '0.25em',
      },
  },
  eWalletLogoContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  eWalletLogo: {
      width: '6em',
      height: 'auto',
      float: 'right',
  },

}));

function Wallet(props) {
  const classes = useStyles()
  let { paymentOptions, handleCancel, selected, handleSelected, paymentData } = props
  const [openLoader, setOpenLoader] = useState(false)
  const [disablePayButton, setDisablePayButton] = useState(true)
  const history = useHistory()
  const paymentWallet = useSelector(state => state.payment.wallet)
  const theme_matches = useTheme()
  const matches_xs = useMediaQuery(theme_matches.breakpoints.down('xs'))
  const matches_sm = useMediaQuery(theme_matches.breakpoints.down('sm'))
  let dispatch = useDispatch()

  const walletNames = {
    paymayaWallet: "Maya",
    gcashWallet: "GCash",
    shopeepayWallet: "ShopeePay",
    bayadWallet: "Bayad"
  }

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#70BF43',
      },
    },
  });
  // useEffect(() => {
  //   if (selected) {
  //     console.log('will enable')
  //     setDisablePayButton(false)
  //   }
  // }, [selected]);

  useEffect(() => {
    if (paymentData.processor) {
      //console.log('will enable')
      setDisablePayButton(false)
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentWallet) {
      const paymayaUrl = paymentWallet.redirectUrl
      console.log(paymentWallet)
      if (paymentWallet.qr_content) {
        history.push(`/qrcode?requestId=${paymentWallet.request_id}`)
      } else if (paymentWallet.redirect_url_app) {
        history.push(`/pending?appUrl=${paymentWallet.redirect_url_app}`)
      } else {
        dispatch(redirectUrl(true))
        history.push(`/redirect?${urlEncode('q', paymayaUrl)}`)
      }
      setOpenLoader(false);
    }
  }, [paymentWallet]);

  return (
    !props ? < ></> :
      <>
        <Container
          direction="column"
          justifyContent='center'
          className={classes.eWalletContainer}
        >
          {
            paymentOptions.options && Object.keys(paymentOptions.options).map((key, i) => {
              return (
                <>
                  <Grid item className={classes.eWalletItem} key={key}>
                    <Grid item xs={2} sm={1} md={2} lg={1} className={classes.eWalletRadioButtonContainer}>
                      <MuiThemeProvider theme={theme}>
                        <Radio
                          checked={selected.toUpperCase() === key.toUpperCase()}
                          onChange={() => handleSelected(key, 'wallet')}
                          value={key}
                          disabled={paymentOptions.options[key].tag ? false : true}
                          size='small'
                          className={classes.eWalletRadioButton}
                        />
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={9} sm={9} md={8} lg={7} className={classes.eWalletLabelContainer}>
                      <Typography className={paymentOptions.options[key].tag ? classes.eWalletLabel : classes.eWalletLabelDisabled}>{walletNames[key]}</Typography>           
                    </Grid>
                    <Grid item xs={8} sm={8} md={6} lg={8} className={classes.eWalletLogoContainer}>
                      <img className={classes.eWalletLogo} src={paymentOptions.options[key].tag ? paymentOptions.options[key].logo : paymentOptions.options[key].disableLogo} />
                    </Grid>
                  </Grid>
                </>
              )
            })

          }
        </Container>
      </>
  );
}

export default Wallet;