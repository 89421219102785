import { TERMINATE_SESSION } from "../Auth/AuthTypes";
import * as types from "../Pgw/PgwTypes";

const INITIAL_STATE = {
    formUpdated: false,
    sessionId: null,
    transaction: null,
    payData: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PGW_MPGS_CREATE_SESSION_SUCCESS:
            return {
                ...state,
                sessionId: action.payload.session.id,
            }
        case types.PGW_MPGS_UPDATE_FORM_SUCCESS:
            return {
                ...state,
                formUpdated: true
            }
        case types.PGW_MPGS_INITIATE_AUTHENTICATION_SUCCESS:
        case types.PGW_MPGS_AUTHENTICATE_SUCCESS:
            return {
                ...state,
                transaction: action.payload,
            }
        case types.PGW_MPGS_PAY_SUCCESS:
            return {
                ...state,
                payData: action.payload,
            }
        case TERMINATE_SESSION:
            return {
                formUpdated: false,
                sessionId: null,
                transaction: null,
                payData: null,
            }
        default:
            return state;
    }
};