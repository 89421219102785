import { Avatar, Card, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import constant from '../../utils/Constants';
import urlEncode from '../../utils/UrlEncode';
import failedLogo from '../assets/failedLogo.png';
import successLogo from '../assets/successLogo.png';
import poweredByBayadLogo from '../../assets/icons/poweredByBayad.png';
import ModalBase from '../ModalBase/ModalBase';
import { redirectUrl } from '../../store/Auth/AuthAction';


const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        margin: 'auto',
        textAlign: 'center',
    },

    cardContainer: {
        padding: 50,
        maxWidth: 700,
    },

    link: {
        marginTop: 30
    },

    card: {
        marginTop: 100
    },

    footerSig: {
        verticanAlign: "middle",
        marginLeft: "auto"
    },

    gridContainer: {
        padding: 30
    }

}));
function BaseStatus(props) {
    const classes = useStyles();
    const [counter, setCounter] = useState(10);
    const dispatch = useDispatch();
    const [locationKeys, setLocationKeys] = useState([])
    const history = useHistory()

    const onClickRedirect = useCallback(() => {
        dispatch(redirectUrl(true))
        history.push(`/redirect?${urlEncode('q', props.merchantLink)}&exit=true`)
    })

    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            if (props.redirect && !props.isBackButtonClicked)
                onClickRedirect();
        }
    })

    return (
        <Container class='containerBg'>
            <Grid
                container
                direction='column'
                justify='space-around'
                alignItems='center'
                className={classes.root}
            >
                <Grid item>
                {props.status == '404' ? 
                    <Grid className={classes.gridContainer}>
                        <Grid item>
                            <Typography variant='h1' >
                                {props.status}
                            </Typography>
                            <Typography variant='subtitle1'>
                                {props.body}
                            </Typography>
                        </Grid>
                    </Grid>
                    :<Card className={classes.card}>
                        <Grid
                            container
                            className={classes.cardContainer}
                            direction='column'
                            justify='center'
                            alignItems='center'
                            spacing={3}
                        >
                            <Grid item>
                                <img src={props.status == 'success' ? successLogo : failedLogo}></img>
                            </Grid>
                            <Grid item>
                                <Typography variant='h4'>
                                    {props.title}
                                </Typography>
                            </Grid>
                            {
                                props.body &&
                                <Grid item>
                                    <Typography variant='subtitle1'>
                                        {props.body && props.body}
                                    </Typography>
                                </Grid>
                            }
                            {
                                props.redirect &&
                                <>
                                    {
                                        props.status !== '404' ?
                                            <Grid item>
                                                {
                                                    props.status === constant.status_failed && props.type === 'Issuer' &&
                                                    <Typography variant='subtitle1'>
                                                        {constant.status_failed_body2}
                                                    </Typography>
                                                }

                                                <Typography variant='subtitle1'>
                                                    {constant.status_redirect_count} {counter}s.
                                                </Typography>
                                            </Grid> : <></>
                                    }

                                    <Grid item class={classes.link}>
                                        <Link component="button" variant="body2" style={{ color: 'black', fontSize: 15 }} onClick={() => onClickRedirect()} >
                                            <u>{props.redirectLabel ? props.redirectLabel : constant.status_redirect_text}</u>
                                        </Link>
                                    </Grid>
                                </>

                            }
                        </Grid>
                    </Card>
                }
                </Grid>
                {
                    props.status !== '404' ?<Grid item>
                    <Container>
                        <span className={classes.footerSig}><img src={poweredByBayadLogo} style={{ verticalAlign: 'middle', height: 150, width: 100 }}></img></span>
                    </Container>
                    </Grid>:<></>
                }
            </Grid>
            <ModalBase open={props.isBackButtonClicked} handleYesModal={onClickRedirect} logo={successLogo} actionText={constant.from_end_action_text} bodyText={constant.from_end_body_text} headerText={constant.from_end_header_text}></ModalBase>
        </Container >
    );
}

export default BaseStatus;