export const PGW_TOKEN_PAYMAYA = 'PGW_TOKEN_PAYMAYA'
export const PGW_TOKEN_MPGS = 'PGW_TOKEN_MPGS'
export const PGW_TOKEN_SUCCESS = 'PGW_TOKEN_SUCCESS'
export const PGW_TOKEN_FAILED = 'PGW_TOKEN_FAILED'

export const PGW_PAYMAYA_CREATE = 'PGW_PAYMAYA_CREATE'
export const PGW_PAYMAYA_CREATE_SUCCESS = 'PGW_PAYMAYA_CREATE_SUCCESS'
export const PGW_PAYMAYA_CREATE_FAILED = 'PGW_PAYMAYA_CREATE_FAILED'

export const PGW_MPGS_CREATE_SESSION = 'PGW_MPGS_CREATE_SESSION'
export const PGW_MPGS_CREATE_SESSION_SUCCESS = 'PGW_MPGS_CREATE_SESSION_SUCCESS'
export const PGW_MPGS_CREATE_SESSION_FAILED = 'PGW_MPGS_CREATE_SESSION_FAILED'

export const PGW_MPGS_UPDATE_FORM = 'PGW_MPGS_UPDATE_FORM'
export const PGW_MPGS_UPDATE_FORM_SUCCESS = 'PGW_MPGS_UPDATE_FORM_SUCCESS'
export const PGW_MPGS_UPDATE_FORM_FAILED = 'PGW_MPGS_UPDATE_FORM_FAILED'

export const PGW_MPGS_INITIATE_AUTHENTICATION = "PGW_MPGS_INITIATE_AUTHENTICATION"
export const PGW_MPGS_INITIATE_AUTHENTICATION_SUCCESS = "PGW_MPGS_INITIATE_AUTHENTICATION_SUCCESS"
export const PGW_MPGS_INITIATE_AUTHENTICATION_FAILED = "PGW_MPGS_INITIATE_AUTHENTICATION_FAILED"

export const PGW_MPGS_AUTHENTICATE = "PGW_MPGS_AUTHENTICATE"
export const PGW_MPGS_AUTHENTICATE_SUCCESS = "PGW_MPGS_AUTHENTICATE_SUCCESS"
export const PGW_MPGS_AUTHENTICATE_FAILED = "PGW_MPGS_AUTHENTICATE_FAILED"

export const PGW_MPGS_REDIRECT_URL = "PGW_MPGS_REDIRECT_URL"
export const PGW_MPGS_REDIRECT_URL_SUCCESS = "PGW_MPGS_REDIRECT_URL_SUCCESS"
export const PGW_MPGS_REDIRECT_URL_FAILED = "PGW_MPGS_REDIRECT_URL_FAILED"

export const PGW_MPGS_PAY = "PGW_MPGS_PAY"
export const PGW_MPGS_PAY_SUCCESS = "PGW_MPGS_PAY_SUCCESS"
export const PGW_MPGS_PAY_FAILED = "PGW_MPGS_PAY_FAILED"
