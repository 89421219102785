import { DUPLICATE_DATA } from "../Payment/PaymentTypes";

const INITIAL_STATE = {
    body: [],
    enables: false,
    header: null,
    merchant: null,
    hasDuplicate: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DUPLICATE_DATA:
            return {
                ...state,
                body: action.payload.body,
                enables: action.payload.enables,
                header: action.payload.header,
                merchant: action.payload.merchant,
                hasDuplicate: action.payload.body.length !== 0 ? true : false
            }
        default:
            return state;
    }
};