import React from 'react';
import constants from '../../utils/Constants';
import {
    Typography,
    Grid,
    useMediaQuery,
    Paper,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSessionId } from '../../utils/Auth';
import failedLogo from '../../components/assets/failedLogo.svg';
import QRCode from 'react-qr-code';
import Loader from '../Loader/Loader';
import ModalBase from '../../components/ModalBase/ModalBase';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { updatePayment } from '../../store/Payment/PaymentAction';
import urlEncode from '../../utils/UrlEncode';
import ExitSurveyModal from '../../components/ExitSurveyModal/ExitSurveyModal';
import { redirectUrl } from '../../store/Auth/AuthAction';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '91.5vh',
        margin: "auto",
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {
            minHeight: '100vh',
        },
    },
    subtitle: {
        color: 'gray',
        marginTop: 15,
        fontSize: 14,
        lineHeight: 1.5,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
    title: {
        color: 'gray',
        fontSize: 16,
        letterSpacing: 1,
        fontWeight: "bold",
        lineHeight: 1.5,
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
    paperContainer: {
        margin: theme.spacing(6),
        borderRadius: 7,
        border: "1px solid #dbdbdb",
        padding: 30,
    },
    totalAmountContainer: {
        marginTop: 10,
        marginBottom: -5,
    },
    totalAmountText: {
        color: 'gray',
        fontSize: 16,
        letterSpacing: 1,
        fontWeight: "bold",
        lineHeight: 1.5,
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
    merchantNameText: {
        color: 'gray',
        marginTop: 15,
        fontSize: 14,
        textTransform: 'uppercase',
        lineHeight: 1.5,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
}));

const QrCode = props => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const payment = useSelector(state => state.payment)
    const paymentUpdate = useSelector(state => state.payment.updateData)
    const [totalAmount, setTotalAmount] = useState('0000')
    const [merchantName, setMerchantName] = useState('')
    const [qrContent, setQrContent] = useState('')
    const [redirect, setRedirect] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    // const [timer, setTimer] = React.useState(100);
    // const idTimer =React.useRef(null);
    // const clear=()=>{window.clearInterval(idTimer.current)}
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const [surveyModalOpen, setSurveyModalOpen] = useState(false)


    const handleBackOnClick = () => {
        dispatch(updatePayment({ paymentId: payment.data.paymentId }, constants.payment_canceled, getSessionId(), null))
        props.sendMessage('Invalidate QR Code')
    }

    useEffect(() => {
        if (payment.data) {
            if (payment.data.totalAmount && payment.processor) {
                setTotalAmount((Number(payment.data.totalAmount.value) + Number(payment.processor.fee)).toFixed(2))
            }

            if (payment.data.merchantName.toUpperCase() === "BAYAD ONLINE") {
                setMerchantName(payment.data.merchantName)
            } else {
                setMerchantName("BAYAD - " + payment.data.merchantName)
            }

            if (payment.wallet) {
                setQrContent(payment.wallet.qr_content)
            }

            setRedirect(`/qrcode?paymentId=${payment.data.paymentId}&referenceNumber=${payment.data.referenceNumber}&totalAmount=${payment.data.totalAmount.value}&bankFee=${payment.processor.fee}`)

            props.openWebsocket(payment.data.paymentId)
        }

        if (paymentUpdate && paymentUpdate.status === 'CANCELLED') {
            dispatch(redirectUrl(true))
            history.push(`/redirect?${urlEncode('q', `${payment.data.redirectUrl.cancel}?paymentId=${payment.data.paymentId}&status=CANCELLED&referenceNumber=${paymentUpdate.referenceNumber}&errorCode=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`)}&exit=true`)
        }
    }, [payment, paymentUpdate])

    // useEffect(()=>{
    //     idTimer.current=window.setInterval(()=>{
    //         setTimer((time)=>time-1)
    //         },1000)
    //     return ()=>clear();
    //     },[])
   
    // useEffect(()=>{
    //    if(timer===0){
    //      clear()
    // }
    // },[timer])

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', onBackButtonClick)
        return () => {
            window.removeEventListener('popstate', onBackButtonClick)
        }
    }, []);

    const onBackButtonClick = (e) => {
        e.preventDefault()
        // setModalOpen(true)
        setSurveyModalOpen(true)
        window.history.pushState(null, null, window.location.pathname)
    }

    return (
        redirect ? <div>
            {
                !matches ? <Header/> : <></>
            }
            <Grid container id='grid-container-qr' className={classes.root}>

                <Grid container item id='grid-container-item-qr' direction='column' justify='center' alignItems='center' className={classes.spiel} >
                    <Grid item id='grid-item-qr-title'>
                        <Typography id='typography-title' className={classes.title} >Scan QR to Pay</Typography>
                    </Grid>
                    <Grid item id='grid-item-qr-subtitle'>
                        <Typography id='typography-subtitle' className={classes.subtitle} color="textSecondary">Use your Shopee App to scan the QR Code ({props.counter}s)</Typography>
                    </Grid>
                    <Grid item id='grid-item-qr-code-section'>
                        <Paper id='paper-qr-code-section' className={classes.paperContainer} elevation={0}>
                            <Grid item id='grid-item-qr-code'>
                                <QRCode
                                    id='qr-code'
                                    title='ShopeePay QR Code'
                                    value={qrContent}
                                    bgColor='white'
                                    fgColor='black'
                                    size={200}
                                />
                            </Grid>
                            <Grid item id='grid-item-total-amount' className={classes.totalAmountContainer}>
                                <Typography id='typography-total-amount' className={classes.totalAmountText} >PHP {totalAmount}</Typography>
                            </Grid>
                            <Grid item id='grid-item-merchant-name'>
                                <Typography id='typography-merchant-name' className={classes.merchantNameText} color="textSecondary" >{merchantName}</Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Grid>
            {
                !matches ? <Footer/> : <></>
            }
            {/* <ModalBase open={modalOpen} setModalOpen={setModalOpen} handleYesModal={handleBackOnClick} logo={failedLogo} actionText={constants.from_end_action_text} bodyText={constants.from_end_body_text} headerText={constants.from_end_header_text}></ModalBase> */}
            <ExitSurveyModal open={surveyModalOpen} setSurveyModalOpen={setSurveyModalOpen} handleYesModal={handleBackOnClick}></ExitSurveyModal>
        </div> :
            <Loader getAuth={false} loader_title={constants.loader_title} loader_body={constants.updating_payment_body} />
    );
};

export default QrCode;