import './App.css';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Loader from './containers/Loader/Loader';
import Transaction from './containers/Transaction/Transaction';
import Status from './containers/Status/Status';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import ThreeDS from './containers/3ds/ThreeDS';
import Pay from './containers/Pay/Pay';
import NotFound from './containers/NotFound/NotFound';
import RedirectTo from './containers/RedirectTo/RedirectTo';
import history from './utils/history';
import theme from './assets/styles/RootTheme'
import { useIdleTimer } from 'react-idle-timer'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SessionTimeout from './components/SessionTimeout/SessionTimeoutDialog'
import MpgsProcess from './containers/MpgsProcess/MpgsProcess';
import moment from 'moment'
import { getSessionId, setSessionId } from './utils/Auth';
import Maintenance from './containers/Maintenance/Maintenance';
import Multiple from './containers/Multiple/Multiple';
import WebsocketClient from './utils/WebsocketClient';
import PendingTransaction from './containers/PendingTransaction/PendingTransaction';


function App() {

  // initial timeout used 900000 == 15 mins
  const [timeout, setTimeout] = useState(900000)
  // time subtracted to parsed expired at 2700000 == 45 mins
  const [defaultTimeout, setDefaultTimeout] = useState(2700000)
  const [lastEvent, setLastEvent] = useState(false)
  const payment = useSelector(state => state.payment.data)

  const handleOnExpire = () => {
    setLastEvent(true)
  }

  const handleStart = () => {
    // console.log(getRemainingTime())
  }

  const {
    reset,
    getRemainingTime,
    pause
  } = useIdleTimer({
    timeout: timeout,
    onIdle: handleOnExpire,
    onActive: handleStart,
    crossTab: {
      emitOnAllTabs: true
    },
    events: [],
    startManually: true
  })

  useEffect(() => {
    if (payment) {
      if (payment.hasOwnProperty('timeRemaining')) {
        console.log(payment.timeRemaining,' time Remaining')
        // const expiryDate = payment.expiresAt.replace(/-/g, "/")
        // console.log(expiryDate)
        // let time = new Date(expiryDate).getTime() - new Date().getTime()
        setTimeout(payment.timeRemaining - defaultTimeout)
      }
    }
  }, [payment])

  useEffect(() => {
    console.log(timeout, 'timeout')
  }, [timeout])

  useEffect(() => {
    if (timeout < 0) {
      handleOnExpire()
    } else if (payment) {
      reset()
    } else {
      pause()
    }
  }, [timeout])

  useEffect(() => {
    if (!getSessionId()) {
      setSessionId()
    }
  })



  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <MuiThemeProvider theme={theme}>
            <SessionTimeout open={lastEvent} setOpen={setLastEvent}></SessionTimeout>
            <PublicRoute path={"/checkout"} restricted={false} component={Loader} />
            <PrivateRoute path={"/transaction"} component={Transaction} />
            <PublicRoute path={"/status/:id"} component={Status} />
            <PrivateRoute path={"/3ds"} component={ThreeDS} />
            <PrivateRoute path={"/multiple-browser-detected"} component={Multiple} />
            <PrivateRoute path={"/pay"} component={Pay} />
            <PrivateRoute path={"/payment-processing"} component={MpgsProcess} />
            <PublicRoute path={"/redirect"} component={RedirectTo} />
            <PublicRoute path={"/404"} restricted={false} component={NotFound} />
            <PublicRoute path={"/maintenance"} restricted={false} component={Maintenance} />
            <PrivateRoute path={"/qrcode"} component={WebsocketClient} />
            <PublicRoute path={"/pending"} restricted={false} component={PendingTransaction} />
          </MuiThemeProvider>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
