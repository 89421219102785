import {
    Grid,
    InputAdornment,
    makeStyles,
    Typography,
    TextField,
    Popover,
    IconButton,
} from '@material-ui/core';
import { Formik, Form, Field, useFormikContext } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import {
    TextField as formikTextField
} from 'formik-material-ui'
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { Info, Visibility, VisibilityOff } from '@material-ui/icons';
import constants, { constantFormat } from '../../../../utils/Constants';
import { setFormValues } from '../../../../store/Payment/PaymentAction'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        // marginTop: 50,
        marginBottom: 23
    },
    label: {
        padding: theme.spacing(1), //grid padding
    },
    adjusted: {
        padding: theme.spacing(1), //grid padding
        minHeight: 34
    },
    actions: {
        paddingTop: theme.spacing(3),
    },

    loader: {
        color: '#fafafa'
    },

    back: {
        cursor: 'pointer',
        textDecoration: 'none'
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverIcon: {
        color: '#909090',
        width: 20,
        [theme.breakpoints.down("xs")]: {
            width: 15,
            marginLeft: -3,
        },
    },
    paper: {
        padding: theme.spacing(1.5),
        backgroundColor: '#0076bf'
    },
    cardIcons: {
        width: 45,
        height: 30,
        [theme.breakpoints.down("xs")]: {
            width: 40,
            height: 25,
        },
    },
    cardFormFields: {
        fontSize: '0.9em',
        [theme.breakpoints.down("xs")]: {
            height: '0.1em',
            fontSize: 10,
        },
    },
    maskButton: {
        cursor: 'pointer',
        color: '#8D8D8D',
        width: 23,
        height: 23,
        marginLeft: 4,
        [theme.breakpoints.down("xs")]: {
            width: 19,
            height: 19,
            marginRight: -4,
        },
    },

}));

const FormObserver = (props) => {
    const { values, isValid } = useFormikContext();
    const dispatch = useDispatch()
    useEffect(() => {
        let valid = Object.values(values).includes('') ? false : isValid
        props.handleFormChange(values, valid)
        // dispatch(setFormValues(values, valid))
    }, [values, isValid]);
    return null;
};



const PgwForm = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const formRef = useRef()
    const { disabled, payment, isPanelSelected} = props
    const [showCardDetails, setShowCardDetails] = useState(false)
    const [cardBackspace, setCardBackspace] = useState(false)
    const [cardDelete, setCardDelete] = useState(false)
    const [cardPastedValue, setCardPastedValue] = useState('')
    const [cardIsPasted, setIsPasted] = useState(false)
    const [cvcPastedValue, setCvcPastedValue] = useState('')
    const [cvcIsPasted, setIsCvcPasted] = useState(false)
    const [cvcBackspace, setCvcBackspace] = useState(false)
    const [cvcDelete, setCvcDelete] = useState(false)
    const [cvcSelectionStart, setCvcSelectionStart] = useState(0)
    const [cvcSelectionEnd, setCvcSelectionEnd] = useState(0)
    const [cardSelectionStart, setCardSelectionStart] = useState(0)
    const [cardSelectionEnd, setCardSelectionEnd] = useState(0)
    const [cardBlur, setCardBlurString] = useState(true)
    const [showCvvDetails, setShowCvvDetails] = useState(false)
    const [cvcBlur, setCvcBlurString] = useState(true)
    const [cardScheme, setCardScheme] = useState({})
    const [dateError, setDateError] = useState({
        'tag': false,
        'msg': ''
    })
    const [validCardSchemeMsg , setValidCardSchemeMsg] = useState("")

    const [minimumYear, setMinimumYear] = useState(0)

    const [initialForm, setInitialForm] = useState({
        cardNumber: '',
        cardMasked: '',
        //cardMaskedFormat: '',
        cardFocus: '',
        expiryDate: '',
        expiryMonth: 13,
        expiryYear: 0,
        cvc: '',
        cvcMasked: '',
        cvcFocus: '',
        firstName: '',
        lastName: '',
        isCardValid: false,
    })
    const [initialValidation, setInitialValidation] = useState({
        cardNumber: Yup.string().required('You missed this! Fill in the required details to proceed.').min(16, validCardSchemeMsg).max(16, validCardSchemeMsg)
            .test(
                "card-valid-check",
                validCardSchemeMsg,
                value => {
                    // handleCheckCardScheme(value)
                    return initialForm.isCardValid
                }
            ),
        expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').max(12, 'Invalid Month'),
        expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.').max(99, 'Invalid Year'),
        expiryDate: Yup.string().required('You missed this! Fill in the required details to proceed.'),
        cvc: Yup.string().required('You missed this! Fill in the required details to proceed.').min(3, 'You missed this! Fill in the required details to proceed.').max(4, 'Invalid'),
        firstName: Yup.string().required('You missed this! Fill in the required details to proceed.'),
        lastName: Yup.string().required('You missed this! Fill in the required details to proceed.'),
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const pgw = useSelector(state => state.pgw)
    const validation = Yup.object(initialValidation)

    const handleCardValid = (isCardValid, cardValue) => {
        if (isCardValid == true && cardValue.length >= 4) {
            setInitialValidation({
                ...initialValidation,
                cardNumber: Yup.number().required('You missed this! Fill in the required details to proceed.').min(4, validCardSchemeMsg)
                .test(
                    "card-valid-check",
                    validCardSchemeMsg,
                    value => {
                        return initialForm.isCardValid
                    }
                ),
            })
        }
        else {
            setInitialValidation({
                ...initialValidation,
                cardNumber: Yup.string().required('You missed this! Fill in the required details to proceed.').min(16, validCardSchemeMsg).max(16, validCardSchemeMsg)
                .test(
                    "card-valid-check",
                    validCardSchemeMsg,
                    value => {
                        return initialForm.isCardValid
                    }
                ),
            })
        }
    }

    useEffect(() => {
        if (isPanelSelected) {
            if (formRef.current) {
                formRef.current.resetForm(formRef.current.initialValues)
            }
        }
    }, [isPanelSelected])


    const handleDateChange = (e, setFieldValue, setFieldError) => {  
        let expDate = e.target.value.replace(/\//g,'')
        let values = e.target.value.split('/')
        let expMonth = '0'
        let expYear = '0'
        let curYear = new Date().getFullYear().toString()
        let curMonth = (new Date().getMonth() + 1).toString()
        curYear = curYear.substring(2)
        if (expDate.length <= 4 || e.nativeEvent.data == null){         
            setFieldValue('expiryDate', e.target.value)            
            values.forEach((date, i) => {
                if (i !== 1) {
                    expMonth = date.length == 2 ? date : '0'
                    let newDate = date ? date : '13'
                    window.requestAnimationFrame(() => {
                        setFieldValue('expiryMonth', parseInt(newDate))
                        setMinimumYear(curYear)
                    })
                    setInitialValidation({
                        ...initialValidation,
                        expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').min(expMonth.length == 2 ? 1 : 20, 'You missed this! Fill in the required details to proceed.').max(99, 'Invalid Year'),
                        expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.')
                    })
                } else {
                    expYear = date.length == 2 ? date : '0'
                    let newDate = date ? date : '0'
                    window.requestAnimationFrame(() => {
                        setFieldValue('expiryYear', parseInt(newDate))
                    })
                    if (expMonth == '0' || expYear == '0'){
                        setInitialValidation({
                            ...initialValidation,
                            expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').min(1, 'Invalid Month').max(12, 'Invalid Month'),
                            expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.')
                        })
                    }
                    else if ((parseInt(curYear) > parseInt(expYear)) || ((parseInt(curMonth) > parseInt(expMonth) && (parseInt(curYear) == parseInt(expYear))))) {
                        setInitialValidation({
                            ...initialValidation,
                            expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').min(0, 'It appears that your card is expired. Please use a different card.').max(0, 'It appears that your card is expired. Please use a different card.'),
                            expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.').min(curYear, 'It appears that your card is expired. Please use a different card.').max(0, 'It appears that your card is expired. Please use a different card.')
                        })          
                    } else {
                        setInitialValidation({
                            ...initialValidation,
                            expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').min(1, 'Invalid Month').max(12, 'Invalid Month'),
                            expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.').max(99, 'Invalid Year'),
                        })
                    }
                }
            })
        }       
    }

    const handleCardOnFocus = (e) => {
        setCardBlurString(true)
    }

    const handleCvvOnFocus = (e) => {
        setCvcBlurString(true)
    }

    const handleDateTouched = (errors, setFieldTouched) => {
        setFieldTouched('expiryDate', true)
    }

    const handleCardPaste = (e) => {
        setCardPastedValue(e.clipboardData.getData('text'))
        setIsPasted(true)
    }

    const handleCvcPaste = (e) => {
        setCvcPastedValue(e.clipboardData.getData('text'))
        setIsCvcPasted(true)
    }

    const handleCardKeyDown = (e) => {
        setCardSelectionStart(e.target.selectionStart)
        setCardSelectionEnd(e.target.selectionEnd)
        if (e.keyCode === 8) {
            setCardBackspace(true)
        }
        else if (e.keyCode === 46){
            setCardDelete(true)
        }
    }

    const handleCvcKeyDown = (e) => {
        setCvcSelectionStart(e.target.selectionStart)
        setCvcSelectionEnd(e.target.selectionEnd)
        if (e.keyCode === 8) {
            setCvcBackspace(true)
        }
        else if (e.keyCode === 46){
            setCvcDelete(true)
        }
    }

    const handleCardChange = (e, setFieldValue, cardNumber, cardMasked, cardFocus, isCardValid) => {
        let value = e.target.value.replace(/\s/g, '')
        let card = cardNumber.replace(/\s/g, '')
        let cursorStart = cardSelectionStart - Math.floor(cardSelectionStart/5)
        let cursorEnd = cardSelectionEnd - Math.floor(cardSelectionEnd/5)
        let data =  (cardBackspace || cardDelete) && card.length != 0 ? 
                    '' : cardIsPasted ? 
                    cardPastedValue.replace(/\s/g, '') : e.nativeEvent.data == null ? 
                    '' : e.nativeEvent.data

        if (!isNaN(data) && (data.length + card.length <= 16) && (!isNaN(value) || data != '') || (cardBackspace || cardDelete || cardPastedValue)){
            if (cardBackspace || cardDelete){
                switch (true){
                    case (cardBackspace && cardSelectionStart == cardSelectionEnd):
                        card = card.slice(0, cursorStart - 1) + card.slice(cursorStart)
                        break

                    case (cardBackspace && cardSelectionStart != cardSelectionEnd):
                        card = card.slice(0, cursorStart) + card.slice(cursorEnd)
                        break

                    case (cardDelete && cardSelectionStart == cardSelectionEnd):
                        card = card.slice(0, cursorStart) + card.slice(cursorStart+1)
                        break

                    case (cardDelete && cardSelectionStart != cardSelectionEnd):
                        card = card.slice(0, cursorStart) + card.slice(cursorEnd)
                        break
                }
            }

            let newCardValue = ''
            switch (true){
                case (card.length == 0):
                    newCardValue = data
                    break
                case (data != '' && data.length <= 1 && card.length != 0):
                    newCardValue = card.slice(0, cursorStart) + data + card.slice(cursorStart)
                    break
                case (data != '' && data.length > 1  && card.length != 0):
                    newCardValue = card.slice(0, (e.target.selectionStart - Math.floor(e.target.selectionStart/5))- data.length) + data + card.slice((e.target.selectionStart - Math.floor(e.target.selectionStart/5))- data.length)
                    break
                default:
                    newCardValue = card
                    break
            }

            let focus = ''
            for (var i = 0; i < newCardValue.length; i++) {
                focus += '\u2022'
                if ((i + 1) % 4 == 0){
                    focus += ' '
                }
            }

            let masked = ''
            for (var i = 0; i < newCardValue.length; i++) {
                switch (true){
                    case (card.length == 0 && i == 0):
                        masked += newCardValue.charAt(i)
                        break

                    case ((cardBackspace && cursorStart != cursorEnd) && i == cursorStart - 1):
                        masked += newCardValue.charAt(i)
                        break
    
                    case ((cardBackspace && cursorStart == cursorEnd) && i == cursorStart - 2):
                        masked += newCardValue.charAt(i)
                        break

                    case (cardDelete && i == cursorStart - 1):
                        masked += newCardValue.charAt(i)
                        break

                    case ((!cardBackspace && !cardDelete) && i === cursorStart):
                        masked += newCardValue.charAt(i)
                        break 

                    default:
                        masked += '\u2022'
                        break
                }

                if (i === 15) {
                    masked += ''
                }
                else if ((i + 1) % 4 == 0){
                    masked += ' '
                }
            }     

            handleCardValid(isCardValid, newCardValue)
            handleCheckCardScheme(newCardValue)
            setFieldValue("cardNumber",newCardValue)
            setFieldValue("cardMasked",masked)
            setFieldValue("cardFocus",focus)

            window.requestAnimationFrame(() => {
                setFieldValue("cardMasked",masked)
            })

            if (!showCardDetails){
                window.requestAnimationFrame(() => {
                    switch(true){
                        case (cardIsPasted):
                            e.target.selectionStart = e.target.selectionStart
                            e.target.selectionEnd = e.target.selectionStart
                            break

                        case (cardDelete):
                            e.target.selectionStart = cardSelectionStart
                            e.target.selectionEnd = cardSelectionStart
                            break

                        case((!cardBackspace && cardSelectionStart == cardSelectionEnd) && (cardSelectionStart + 1) % 5 == 0 && cardSelectionStart != 0):
                            e.target.selectionStart = cardSelectionStart + 2
                            e.target.selectionEnd = cardSelectionStart + 2
                            break
                            
                        case((!cardBackspace && cardSelectionStart == cardSelectionEnd)):
                            e.target.selectionStart = cardSelectionStart + 1
                            e.target.selectionEnd = cardSelectionStart + 1
                            break

                        case (!cardBackspace && cardSelectionStart != cardSelectionEnd):
                            e.target.selectionStart = cardSelectionEnd
                            e.target.selectionEnd = cardSelectionEnd
                            break

                        case ((cardBackspace && cardSelectionStart == cardSelectionEnd) && (cardSelectionStart % 5 == 0 && cardSelectionStart != 0)):
                            e.target.selectionStart = cardSelectionStart - 2
                            e.target.selectionEnd = cardSelectionStart - 2
                            break

                        case ((cardBackspace && cardSelectionStart == cardSelectionEnd)):
                            e.target.selectionStart = cardSelectionStart - 1
                            e.target.selectionEnd = cardSelectionStart - 1
                            break

                        case (cardBackspace && cardSelectionStart != cardSelectionEnd):
                            e.target.selectionStart = cardSelectionStart
                            e.target.selectionEnd = cardSelectionStart
                            break

                        default:
                            e.target.selectionStart = e.target.value.length
                            e.target.selectionEnd = e.target.value.length
                            break
                    }
                })
            }
        }
        else {

            handleCardValid(isCardValid, card)
            handleCheckCardScheme(card)
            setFieldValue("cardNumber",card)
            setFieldValue("cardFocus",cardFocus)

            window.requestAnimationFrame(() => {
                setFieldValue("cardMasked",cardMasked)
                e.target.selectionStart = cardSelectionStart
                e.target.selectionEnd = cardSelectionStart
            })
        }

        setCardBackspace(false)
        setCardDelete(false)
        setIsPasted(false)
        setCardPastedValue('')
    }

    const handleCvvChange = (e, setFieldValue, cvc, cvcMasked, cvcFocus) => {
        let value = e.target.value.replace(/\s/g, '');
        let cursorStart = cvcSelectionStart
        let cursorEnd = cvcSelectionEnd
        let data = (cvcBackspace || cvcDelete) && cvc.length != 0 ? 
                    '' : cvcIsPasted ? 
                    cvcPastedValue.replace(/\s/g, '') : e.nativeEvent.data == null ? 
                    '' : e.nativeEvent.data

        if (!isNaN(data) && (cvc.length + data.length <= 3) && (!isNaN(value) || data != '') || ((cvcBackspace || cvcDelete || cvcIsPasted))){
            if (cvcBackspace || cvcDelete){
                switch (true){
                    case (cvcBackspace && cvcSelectionStart == cvcSelectionEnd):
                        cvc = cvc.slice(0, cursorStart - 1) + cvc.slice(cursorStart)
                        break

                    case (cvcBackspace && cvcSelectionStart != cvcSelectionEnd):
                        cvc = cvc.slice(0, cursorStart) + cvc.slice(cursorEnd)
                        break

                    case (cvcDelete && cvcSelectionStart == cvcSelectionEnd):
                        cvc = cvc.slice(0, cursorStart) + cvc.slice(cursorStart+1)
                        break

                    case (cvcDelete && cvcSelectionStart != cvcSelectionEnd):
                        cvc = cvc.slice(0, cursorStart) + cvc.slice(cursorEnd)
                        break
                }
            }
            
            switch (true){
                case (cvc.length == 0):
                    cvc = data
                    break

                case (data != null && data.length <= 1 && cvc.length != 0):
                    cvc = cvc.slice(0, cursorStart) + data + cvc.slice(cursorStart)
                    break

                case (data != null && data.length > 1 && cvc.length != 0):
                    cvc = cvc.slice(0, e.target.selectionStart - data.length) + data + cvc.slice(e.target.selectionStart - data.length)
                    break

                default:
                    break
            }

            let focus = ''
            for (var i = 0; i < cvc.length; i++) {
                focus += '\u2022'
            }

            let masked = ''
            for (var i = 0; i < cvc.length; i++) {
                switch (true){
                    case (cvc.length == 0 && i == 0):
                        masked += cvc.charAt(i);
                        break

                    case (cvcBackspace && i === cursorStart - 2):
                        masked += cvc.charAt(i);
                        break

                    case (cvcDelete && i === cursorStart - 1):
                        masked += cvc.charAt(i);
                        break

                    case ((!cvcBackspace && !cvcDelete) && i === cursorStart):
                        masked += cvc.charAt(i)
                        break 

                    default: 
                        masked += '\u2022'
                        break
                }
            }

            setFieldValue("cvc",cvc)
            setFieldValue("cvcFocus",focus)

            window.requestAnimationFrame(() => {
                setFieldValue("cvcMasked",masked)
            })

            if (!showCardDetails){
                window.requestAnimationFrame(() => {
                    switch(true){
                        case (cvcIsPasted):
                            e.target.selectionStart = e.target.selectionStart
                            e.target.selectionEnd = e.target.selectionStart
                            break

                        case (cvcDelete):
                            e.target.selectionStart = cursorStart
                            e.target.selectionEnd = cursorStart
                            break
                            
                        case((!cvcBackspace && cvcSelectionStart == cvcSelectionEnd)):
                            e.target.selectionStart = cursorStart + 1
                            e.target.selectionEnd = cursorStart + 1
                            break

                        case (!cvcBackspace && cvcSelectionStart != cvcSelectionEnd):
                            e.target.selectionStart = cursorEnd
                            e.target.selectionEnd = cursorEnd
                            break

                        case ((cvcBackspace && cvcSelectionStart == cvcSelectionEnd)):
                            e.target.selectionStart = cursorStart - 1
                            e.target.selectionEnd = cursorStart - 1
                            break

                        case (cvcBackspace && cvcSelectionStart != cvcSelectionEnd):
                            e.target.selectionStart = cursorStart
                            e.target.selectionEnd = cursorStart
                            break

                        default:
                            e.target.selectionStart = cursorStart
                            e.target.selectionEnd = cursorStart
                            break
                    }
                })
            }
        }
        else {
            setFieldValue("cvc",cvc)
            setFieldValue("cvcFocus",cvcFocus)
            window.requestAnimationFrame(() => {
                setFieldValue("cvcMasked",cvcMasked)
                e.target.selectionStart = cursorStart
                e.target.selectionEnd = cursorStart
            })
        }

        setCvcBackspace(false)
        setCvcDelete(false)
        setCvcPastedValue('')
        setIsCvcPasted(false)
    }

    const handleCardTouched = (errors, setFieldTouched) => {
        setCardBlurString(false)
        setFieldTouched("cardNumber", true)
    }

    const handleCvcTouched = (e, setFieldTouched) => {
        setCvcBlurString(false)
        setFieldTouched("cvc", true)
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleCheckCardScheme = (value) => {
        if (value && value.length > 4) {
            value = value.substring(0, 4)
        }
        props.handleCardNumField(value)
    }

    const handleCardFieldMask = () => {
        setShowCardDetails(!showCardDetails)
    };

    const handleCvvFieldMask = () => {
        setShowCvvDetails(!showCvvDetails)
    };

    useEffect(() => {
        if (payment.merchantCustomerInformation && payment.merchantCustomerInformation.billingAddress) {
            setInitialForm({
                ...initialForm,
                line1: '',
                line2: '',
                city: '',
                state: '',
                zipCode: '',
                country: 'PH'
            })
            setInitialValidation({
                ...initialValidation,
                line1: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                line2: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                city: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                state: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                country: Yup.string().required('You missed this! Fill in the required details to proceed.')
            })
        }
        setValidCardSchemeInlineMgs()
    }, [payment])

    const setValidCardSchemeInlineMgs = () => {
        // SET INLINE MESSAGE FOR VALID CARD SCHEME
        if (payment) {
            let cards = payment.merchantPaymentOptions.cards
            let validCards = []
            let msg = ""

            Object.entries(cards).map(([key, value]) => {
                if(value) validCards.push(key)
            })
            if (validCards.length === 1) {
                msg = constants['card_scheme_'+validCards[0].toLowerCase()]
                setValidCardSchemeMsg(constantFormat({card : msg},constants.card_scheme_valid_msg))
            } else {
                validCards.map((value, index) => {
                    if (validCards.length === index + 1) {
                        msg = msg + "and " + constants['card_scheme_'+value.toLowerCase()];
                    } else {
                        msg = msg + constants['card_scheme_'+value.toLowerCase()] + ', ';
                    }
                })
                msg = msg.replace(', and', ' and')
            }
            
            setValidCardSchemeMsg(constantFormat({card : msg},constants.card_scheme_valid_msg))
        }
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={initialForm}
                validationSchema={validation}
                innerRef={formRef}
            >
                {({
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    values,
                    errors,
                    touched,
                    isValid
                }) => (
                    <Form>
                        <FormObserver handleFormChange={props.handleFormChange} valuestest={values}></FormObserver>
                        <Grid
                            container
                            spacing={3}
                            id='grid-container-pgw-form'
                        >
                            <Grid item xs={12} sm={12} md={12} id='grid-item-card-number'>
                                <Field id='field-card-number' name='cardNumber'>
                                    {(fields) => (
                                        <InputMask
                                            id='inputmask-card-number'
                                            inputProps={{ inputMode: 'numeric' }}
                                            mask= {showCardDetails ? '9999 9999 9999 9999' : disabled}
                                            onKeyDown={(e) => handleCardKeyDown(e)}
                                            onPaste={(e) => handleCardPaste(e)}
                                            onChange={(e) => handleCardChange(e, setFieldValue, values.cardNumber, values.cardMasked, values.cardBlur, initialForm.isCardValid)}
                                            onBlur={() => handleCardTouched(errors, setFieldTouched)}
                                            value={showCardDetails ? values.cardNumber : cardBlur ? values.cardMasked : values.cardFocus}
                                            onFocus={(e) => handleCardOnFocus(e)}
                                            maskPlaceholder={""}
                                            disabled={disabled}
                                            name='cardNumber'
                                        >
                                            <TextField
                                                id='textfield-card-number'
                                                type='text'
                                                variant="outlined"
                                                name='cardNumber'
                                                fullWidth
                                                autoComplete='off'
                                                placeholder="16-digit Card Number"
                                                error={errors.cardNumber && touched.cardNumber && true}
                                                helperText={errors.cardNumber && touched.cardNumber && errors.cardNumber}
                                                InputProps={{
                                                    classes: { input: classes.cardFormFields },
                                                    endAdornment: (
                                                        <InputAdornment 
                                                            id='end-adornment-card-number' 
                                                            position="end"
                                                        >
                                                            {props.cardLogo && initialForm.isCardValid == true && values.cardNumber.length >= 4 ?
                                                                <img src={props.cardLogo} className={classes.cardIcons} />
                                                                : <></>
                                                            }
                                                            {props.cardLogo ? initialForm.isCardValid = true : initialForm.isCardValid = false}
                                                            <IconButton
                                                                id='button-card-number-mask'
                                                                size={'small'}
                                                                onClick={handleCardFieldMask}
                                                            >
                                                                {showCardDetails ? 
                                                                    <Visibility 
                                                                        id='button-show-card-number' 
                                                                        className={classes.maskButton} 
                                                                    /> 
                                                                    : 
                                                                    <VisibilityOff 
                                                                        id='button-hide-card-number' 
                                                                        className={classes.maskButton} 
                                                                    />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </InputMask>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Field name='expiryDate'>
                                    {(fields) => (
                                        <InputMask
                                            mask="99/999"
                                            inputProps={{ inputMode: 'numeric' }}
                                            maskPlaceholder={null}
                                            onChange={e => handleDateChange(e, setFieldValue, setFieldError)}
                                            onBlur={() => handleDateTouched(errors, setFieldTouched)}
                                            value={values.expiryDate}
                                            disabled={disabled}
                                            InputProps={{ classes: { input: classes.cardFormFields } }}
                                        >
                                            <TextField
                                                variant="outlined"
                                                name='expiryDate'
                                                fullWidth
                                                autoComplete='off'
                                                placeholder="Expiry (MM/YY)"
                                                error={(errors.expiryDate || errors.expiryMonth || errors.expiryYear) && touched.expiryDate && true}
                                                helperText={
                                                    (errors.expiryDate || errors.expiryMonth || errors.expiryYear) && touched.expiryDate && (errors.expiryDate || errors.expiryMonth || errors.expiryYear)
                                                }
                                            />
                                        </InputMask>
                                    )}
                                </Field>
                                <input type='hidden' name='expirtMonth' value={values.expiryMonth}></input>
                                <input type='hidden' name='expirtYear' value={values.expiryYear}></input>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} id='grid-item-cvc'>
                                <Field id='field-cvc' name='cvc'>
                                    {(fields) => (
                                        <InputMask
                                            id='input-mask-cvc'
                                            //mask="999"
                                            mask= {showCvvDetails ? '999' : disabled}
                                            type= 'text'
                                            inputProps={{ inputMode: 'numeric' }}
                                            onKeyDown={(e) => handleCvcKeyDown(e)}
                                            onPaste={(e) => handleCvcPaste(e)}
                                            onChange={(e) => handleCvvChange(e, setFieldValue, values.cvc, values.cvcMasked, values.cvcFocus)}
                                            onBlur={(e) => handleCvcTouched(e, setFieldTouched)}
                                            onFocus={(e) => handleCvvOnFocus(e)}
                                            value={showCvvDetails ? values.cvc : cvcBlur ? values.cvcMasked : values.cvcFocus}
                                            maskPlaceholder={null}
                                            disabled={disabled}
                                        >
                                            <TextField
                                                id='text-field-cvc'
                                                variant="outlined"
                                                name='cvc'
                                                fullWidth
                                                placeholder="CVV/CVC"
                                                autoComplete='off'
                                                error={errors.cvc && touched.cvc}
                                                helperText={errors.cvc && touched.cvc && errors.cvc}
                                                InputProps={{
                                                    classes: { input: classes.cardFormFields },
                                                    startAdornment: (
                                                        <InputAdornment 
                                                            id='start-adornment-cvc' 
                                                            position="start"
                                                        >
                                                            <Info
                                                                id='info-cvc'
                                                                color='initial'
                                                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                onMouseEnter={handlePopoverOpen}
                                                                onMouseLeave={handlePopoverClose}
                                                                className={classes.popoverIcon}
                                                            ></Info>
                                                            <Popover
                                                                id="mouse-over-popover"
                                                                className={classes.popover}
                                                                classes={{
                                                                    paper: classes.paper,
                                                                }}
                                                                open={open}
                                                                anchorEl={anchorEl}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                onClose={handlePopoverClose}
                                                                disableRestoreFocus
                                                            >
                                                                <Typography id= 'typography-cvc' variant='caption'>You can find this 3 or 4 digit code at the back of your card, beside your signature.</Typography>
                                                            </Popover>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment 
                                                            id='end-adornment-cvc' 
                                                            position="end"
                                                        >
                                                            <IconButton
                                                                id='button-cvc-mask'
                                                                size={'small'}
                                                                onClick={handleCvvFieldMask}
                                                            >
                                                                {showCvvDetails ?  
                                                                    <Visibility 
                                                                        id='button-show-cvc' 
                                                                        className={classes.maskButton} 
                                                                    /> 
                                                                    : 
                                                                    <VisibilityOff 
                                                                        id='button-hide-cvc' 
                                                                        className={classes.maskButton} 
                                                                    />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </InputMask>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Field
                                    name='firstName'
                                    variant="outlined"
                                    component={formikTextField}
                                    value={values.firstName}
                                    placeholder="First Name"
                                    fullWidth
                                    disabled={disabled}
                                    InputProps={{ classes: { input: classes.cardFormFields } }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Field
                                    name='lastName'
                                    variant="outlined"
                                    component={formikTextField}
                                    value={values.lastName}
                                    placeholder="Last Name"
                                    fullWidth
                                    disabled={disabled}
                                    InputProps={{ classes: { input: classes.cardFormFields } }}
                                />
                            </Grid>
                            {

                                payment.merchantCustomerInformation && payment.merchantCustomerInformation.billingAddress ?
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Field
                                                name='line1'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.line1}
                                                placeholder="House/Unit No., Street"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='line2'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.line2}
                                                placeholder="Subdivision, Barangay"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='city'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.city}
                                                placeholder="City"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='state'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.state}
                                                placeholder="Province"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='country'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.country}
                                                placeholder="Country"
                                                fullWidth
                                                disabled={true}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                    </>
                                    : <> </>
                            }


                        </Grid>
                    </Form>
                )}
            </Formik>
        </div >
    );
}

export default PgwForm;