import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockModal from '../../components/BlockModal/BlockModal';
import DuplicatePromptModal from '../../components/DuplicatePromptModal/DuplicatePromptModal';

const Multiple = (props) => {

    return (
        <div>
            <BlockModal open={true} multipleBrowserDetection={true}>

            </BlockModal>
        </div>
    );
}

export default Multiple;