import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const SnackBarAlert = (props) => {
    let { open, handleClose, message, type } = props
    return (
        <div>

            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title"><ErrorOutlineIcon color='error' style={{ verticalAlign: 'middle', fontSize: 25, marginRight: 5 }}></ErrorOutlineIcon>{message}</DialogTitle>
            </Dialog>
            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert
                    onClose={handleClose}
                    severity={type}
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"  
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleClose()
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {message}
                </Alert>
            </Snackbar> */}
        </div>
    );
}

export default SnackBarAlert;