import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Typography
} from '@material-ui/core';

import failedLogo from '../../components/assets/failedLogo.png'
import warningPrompt from '../../components/assets/warningPrompt.png'
import Constants from '../../utils/Constants';


const useStyles = makeStyles((theme) => ({

    centerTitle: {
        textAlign: 'center',
        marginTop: 25
    },

    actionContainer: {
        maxWidth: '50%',
        paddingTop: 25,
        paddingBottom: 25,
        margin: 'auto'
    },

    dialogBody: {
        textAlign: 'center',
    },

    dialogText: {
        color: '#646567',
        fontSize: 14,
        fontWeight: 200,
        marginBottom: 40
    },

    titleText: {
        marginTop: 20,
    }

}));

const BlockModal = props => {
    const { open, multipleBrowserDetection = false } = props
    const classes = useStyles()
    return (
        <div>
            <Dialog
                open={open}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth="true"
                maxWidth="xs"
            >
                <DialogTitle className={classes.centerTitle} id="scroll-dialog-title">
                    <img src={multipleBrowserDetection ? warningPrompt : failedLogo}></img>
                    <Typography variant='h2' className={classes.titleText}>{Constants.block_title}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Typography variant='h3' className={classes.dialogText}>
                            {/* Clicking the back button means your payment session needs to restart. Don't worry, no charges were made on your card. */}
                            {Constants.block_body}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BlockModal;