import React from 'react';
import "./dotloader.css"
const DotLoader = () => {
    return (
        <div>
            <div class="container">
                <div id="first" class="dot"></div>
                <div id="second" class="dot"></div>
                <div id="third" class="dot"></div>
                <div id="fourth" class="dot"></div>
                <div id="fifth" class="dot"></div>
            </div>
        </div>
    );
};

export default DotLoader;