import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@mui/material/Link';
import pcidssLogo from '../../assets/icons/PCI-DSSLogo.png'
import { useSelector } from 'react-redux';
import PrivacyModal from '../../components/PivacyModal/PrivacyModal';

const useStyles = makeStyles((theme) => ({

    root: {
        position: 'sticky',
        paddingTop: 5,
        paddingBottom: 5,
    },

    footer: {
        display: "flex",
        margin: 'auto',
        maxWidth: '62rem',
        [theme.breakpoints.only("md")]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.only("sm")]: {
            maxWidth: '80%',
            marginBottom: 10,
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: '90%',
            marginBottom: 10,
        },
    },

    privacyPolicyLink: {
        color: 'blue',
        textDecoration: 'none',
        cursor: 'pointer',
    },

    privacyPolicy: {
        verticalAlign: "middle",
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: "auto",
        textAlign: "left",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 1,
            marginRight: 40,
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: 1,
            marginRight: 40,
        },
    },

    pcidss: {
        verticalAlign: "middle",
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: "auto",
        [theme.breakpoints.down("xs")]: {
            marginRight: 1,
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: 1,
        },
    },

    pcidssLogo: {
        verticalAlign: 'middle', 
        width: 50, 
        height: 24,
    },

}));

const Transaction = props => {
    const classes = useStyles();
    const payment = useSelector(state => state.payment)
    const [privacyModal, setPrivacyModal] = useState(false)
    const [privacyModalTabValue, setPrivacyModalTabValue] = useState(0)

    const handlePrivacyModal = (value) => {
        setPrivacyModal(!privacyModal)
        if (value === 'TC') {
            setPrivacyModalTabValue(0)
        }
        else if (value === 'Privacy') {
            setPrivacyModalTabValue(1)
        }
    }

    return (
        !payment.data ? <></> :
            <div className={classes.root}>
                <PrivacyModal open={privacyModal} tabValue={privacyModalTabValue} handleModal={handlePrivacyModal}></PrivacyModal>   
                <Grid item className={classes.footer} >
                    {
                        payment.data.merchantLinks && payment.data.merchantLinks.termsAndConditions.tag && payment.data.merchantLinks.privacyPolicy.tag ?
                            <span className={classes.privacyPolicy}>By using Bayad Service you've agreed to the <Link color='primary' underline='none' className={classes.privacyPolicyLink} onClick={() => handlePrivacyModal('TC')}>Terms & Conditions</Link> and <Link color='primary' underline='none' className={classes.privacyPolicyLink} onClick={() => handlePrivacyModal('Privacy')}>Privacy Policy</Link>.</span> :
                            <></>
                    }
                    <span className={classes.pcidss}><img src={pcidssLogo} className={classes.pcidssLogo}></img></span>
                </Grid>
            </div>
    );
}

export default Transaction;