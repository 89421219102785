import * as types from "../Auth/AuthTypes";

const INITIAL_STATE = {
    accessToken: null,
    isAuth: false,
    redirectUrl: false,
    paymentId : null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_AUTH_SUCCESS:
            return {
                accessToken: action.payload,
                isAuth: true
            }
        case types.GET_PAYMENT_ID:
            return {
                ...state,
                paymentId: action.payload
            }
        case types.TERMINATE_SESSION:
            return {
                accessToken: null,
                isAuth: false
            }
        case types.REMOVE_AUTH:
            return {
                accessToken: null,
                isAuth: false
            }
        case types.REDIRECT_URL:
            return {
                ...state,
                redirectUrl : action.allow
            };
        default:
            return state;
    }
};
