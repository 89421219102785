import { all } from 'redux-saga/effects'
import watchAuthRequests from './Auth/AuthSaga'
import watchPaymentRequests from './Payment/PaymentSaga'
import watchPgwRequests from './Pgw/PgwSaga'

export default function* rootSaga() {
    yield all([
        watchAuthRequests(),
        watchPaymentRequests(),
        watchPgwRequests()
    ])
}