import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    centerTitle: {
        textAlign: 'center',
        marginTop: 25
    },

    actionContainer: {
        maxWidth: '50%',
        paddingTop: 25,
        paddingBottom: 25,
        margin: 'auto'
    },

    dialogBody: {
        textAlign: 'center',
    },

    dialogText: {
        color: '#646567',
        fontSize: 14,
        fontWeight: 200
    },

    titleText: {
        marginTop: 20,
    }

}));
const ModalBase = props => {

    const classes = useStyles()
    const { open, onClose, clickCloseOutside = true, handleYesModal } = props
    const handleClose = () => {
        if (clickCloseOutside) props.setModalOpen(false)
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={props.open}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle className={classes.centerTitle} id="scroll-dialog-title">
                    <img src={props.logo}></img>
                    <Typography variant='h2' className={classes.titleText}>{props.headerText}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Typography variant='h3' className={classes.dialogText}>
                            {/* Clicking the back button means your payment session needs to restart. Don't worry, no charges were made on your card. */}
                            {props.bodyText}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <Button variant='contained' color="primary" fullWidth='false' onClick={handleYesModal}>
                        {props.actionText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default ModalBase;