import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Card, Container, Grid, makeStyles, Typography, Popover, Button, useMediaQuery} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import Header from '../../components/Header/Header'
import { terminate } from '../../store/Auth/AuthAction';
import constant from '../../utils/Constants';
import maintenanceLeft from '../../assets/left.png'
import maintenanceRight from '../../assets/right.png'
import maintenance from '../../assets/main.png'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '95vh',
        margin: 'auto',
        textAlign: 'center',
        color: '#58595B',
        [theme.breakpoints.down("xs")]: {
            paddingLeft: '15px',
            paddingRight: '15px'
        },
    },
    maintenanceLeft: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        [theme.breakpoints.down("xs")]: {
            height: '5vh',
        },
    },
    maintenanceRight: {
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        [theme.breakpoints.down("xs")]: {
            height: '15vh',
        },
    },
    maintenance: {
        [theme.breakpoints.down("xs")]: {
            height: '20vh',
        },
    },
    container: {
        height: '10vh',
        minHeight: '100%',
        maxWidth: '100%',

    },
    maintenanceHeader: {
        paddingTop: '30px',
        paddingBottom: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: '#373D3F',
        [theme.breakpoints.down("xs")]: {
            fontSize: 15,
        },
    },
    maintenanceText: {
        color: '#646567',
        fontSize: 14,
        fontWeight: 200,
        lineHeight: 2,
        [theme.breakpoints.down("xs")]: {
            fontSize: 11
        },
    }

}));

const Maintenance = (props) => {
    const classes = useStyles();
    const token = useSelector(state => state.token)
    const payment = useSelector(state => state.payment)
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'))


    useEffect(() => {
        if (token.isAuth) {
            dispatch(terminate());
        }
    }, [payment])

    return (

        <div>
            {
                process.env.REACT_APP_MAINTENANCE_MODE == 1 ?
                    <Container className={classes.container}>
                        {
                            !matches ? <Header/> : <></>
                        }
                        <img className={classes.maintenanceLeft} src={maintenanceLeft}></img>
                        <img className={classes.maintenanceRight} src={maintenanceRight}></img>
                        <Grid
                            container
                            component="main"
                            className={classes.root}
                            direction='column'
                            justify='center'
                            alignItems='center'
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid
                                    container
                                    direction='column'
                                >
                                    <Grid item>
                                    <img className={classes.maintenance} src={maintenance}></img>
                                    </Grid>
                                    <Grid item className={classes.maintenanceHeader}>
                                        <Typography variant='h4'>
                                            {constant.maintenance_mode_header}
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant='subtitle2' className={classes.maintenanceText}>
                                            {constant.maintenance_mode_body} {constant.maintenance_mode_body1}
                                        </Typography>
                                        <Typography variant='subtitle2' className={classes.maintenanceText}>
                                            {constant.maintenance_mode_body2}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} ></Grid>
                        </Grid>
                    </Container> : <>.</>
            }
        </div>
    );
}

export default Maintenance;