import { select } from "@redux-saga/core/effects";
import { paymentData } from "../selectors";
import {
    PGW_MPGS_CREATE_SESSION,
    PGW_MPGS_INITIATE_AUTHENTICATION,
    PGW_MPGS_PAY,
    PGW_MPGS_UPDATE_FORM,
    PGW_MPGS_WATCH_AUTH,
    PGW_TOKEN_PAYMAYA,
} from "./PgwTypes";

export const pgwTokenRequest = (data, processor) => {
    let reducer = {
        type: '',
        data: data
    }

    switch (processor) {
        case 'paymaya':
            reducer.type = PGW_TOKEN_PAYMAYA
            break
        case 'mpgs':
            reducer.type = PGW_MPGS_CREATE_SESSION
            break
        default:
            break
    }

    return reducer
}

export const mpgsProcess = (sessionId, paymentId) => {
    return {
        type: PGW_MPGS_INITIATE_AUTHENTICATION,
        data: {
            sessionId: sessionId,
            paymentId: paymentId
        }
    }
}

export const mpgsPay = () => {
    return {
        type: PGW_MPGS_PAY,
        data: null
    }
}