import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '12px',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const Loader = ({ variant, size }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress size={size} variant={variant} />
    </div>
  )
}

Loader.propTypes = {
  variant: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

export default Loader