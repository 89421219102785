import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { updatePayment, watchPayment } from '../../store/Payment/PaymentAction';
import {
    makeStyles,
} from '@material-ui/core';
import ModalBase from '../../components/ModalBase/ModalBase';
import successLogo from '../../components/assets/successLogo.svg'
import Constants from '../../utils/Constants';
import urlEncode from '../../utils/UrlEncode';
import { getLocalPaymentId, getSessionId, clearSessionId } from '../../utils/Auth';
import { redirectUrl } from '../../store/Auth/AuthAction';
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '4vh'
    },
}));


const ThreeDS = props => {
    const query = useQuery();
    const dispatch = useDispatch();
    const pgw = useSelector(state => state.pgwData)
    const payment = useSelector(state => state.payment)
    const history = useHistory()
    const classes = useStyles()
    const [isBackButtonClicked, setBackButtonClicked] = useState(false)
    const paymentUpdate = useSelector(state => state.payment.updateData)

    const handleRedirect = () => {
        // dispatch(updatePayment({ paymentId: getLocalPaymentId() }, Constants.payment_canceled));
        dispatch(updatePayment({ paymentId: getLocalPaymentId() }, Constants.payment_canceled, getSessionId(), null));
    }

    useEffect(() => {
        if (pgw.transaction) {
            let threeDs1 = document.getElementById('redirectTo3ds1Form')
            let frictionLess = document.getElementById('threedsFrictionLessRedirectForm')
            let challege = document.getElementById('threedsChallengeRedirectForm')
            let threedsFrame = document.getElementById('redirectTo3ds1AcsSimple')
            if (threedsFrame) {
                threedsFrame.style.height = "99vh"
            }
            if (threeDs1) {
                threeDs1.submit()

            }
            if (frictionLess) {
                frictionLess.submit()
            }
            if (challege) {
                challege.submit()
            }
            dispatch(watchPayment(payment.data.paymentId, false))
        }
    }, []);



    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        setBackButtonClicked(true)
    }

    useEffect(() => {
        if (payment.data) {

            if (payment.data.pgwStatus === "AUTHENTICATED") {
                console.log(payment.data.pgwStatus)
                history.push('/pay?q=execute')
            } else if (payment.data.pgwStatus === "FAILED") {
                console.log(payment.data.pgwStatus)
                history.push('/status/failed')
            }
        }
    }, [payment])

    useEffect(() => {
        let url = ""
        if (paymentUpdate) {
            dispatch(redirectUrl(true))
            switch (paymentUpdate.status) {
                case Constants.payment_canceled:
                    url = `${payment.data.redirectUrl.cancel}?paymentId=${getLocalPaymentId()}&status=CANCELLED&referenceNumber=${paymentUpdate.referenceNumber}&errorCode=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`
                    history.push('/redirect?' + urlEncode('q', url))
                    break
                case Constants.payment_expired:
                    url = `${payment.data.redirectUrl.cancel}?paymentId=${getLocalPaymentId()}&status=EXPIRED&referenceNumber=${paymentUpdate.referenceNumber}&errorCode=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`
                    history.push('/redirect?' + urlEncode('q', url))
                    break
                default:
                    dispatch(redirectUrl(false))
                    break
            }
        }
        // }
    }, [paymentUpdate])

    return (
        // <iframe id='test' title='test'>
        <div>
            <div dangerouslySetInnerHTML={{ __html: pgw.transaction && pgw.transaction.authentication.redirectHtml.replace(/\\/g, '') }} className={classes.root}>
            </div >
            <ModalBase open={isBackButtonClicked} handleYesModal={handleRedirect} logo={successLogo} actionText={Constants.from_end_action_text} bodyText={Constants.from_end_body_text} headerText={Constants.from_end_header_text}></ModalBase>
        </div>


        // </iframe>

        // <div id="threedsChallengeRedirect" style={{ height: '100vh' }}>
        //     <form
        //         id="threedsChallengeRedirectForm"
        //         method="POST"
        //         action={pgw.transaction && pgw.transaction.authentication.redirect.customized['3DS'].acsUrl}
        //         target="challengeFrame"
        //     >
        //         <input type="hidden" name="creq" value={pgw.transaction && pgw.transaction.authentication.redirect.customized['3DS'].cReq} />
        //     </form>
        //     <iframe id="challengeFrame" name="challengeFrame" width="100%" height="100%" onLoad={() => console.log('loaded')} >
        //     </iframe>
        // </div>
    );
};

export default ThreeDS