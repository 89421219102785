import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.down("xs")]: {
            paddingTop: 14,
        },
    },

    privacyLink: {
        color: 'white'
    }

}));

const PrivacyAlert = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(true);
    const [errorOpen, setErrorOpen] = useState(false);
    const [closeTime, setCloseTime] = useState(5);
    const [hideTime, setHideTime] = useState(6);
    const { handleModal } = props
    const handleClose = () => {
        setOpen(false);
    };
    const hide = () => {
        document.getElementById('privacy-alert').style.display = 'none'
    };
    const handleClickClose = () => {
        setOpen(false)
        setHideTime(1)
    };
    const handleErrorClose = () => {
        setErrorOpen(!errorOpen)
    }

    useEffect(() => {
        if (closeTime > 0) {
            setTimeout(() => setCloseTime(closeTime - 1), 1000);
        } else {
            handleClose()
        }
    })
    useEffect(() => {
        if (hideTime > 0) {
            setTimeout(() => setHideTime(hideTime - 1), 1000);
        } else {
            if (isMobile)
                hide()
        }
    })

    return (
        <div id= 'privacy-alert' className={classes.root}>
            <Fade in={open}>
                <Alert
                    icon={false}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleClickClose()
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    variant="filled"
                    severity="info"
                >
                    <Typography variant="caption" style={{ fontStyle: 'default' }} >
                        Bayad will only collect personal data that are necessary to process your payment including your card information. Read more about our <Link to="/transaction" onClick={handleModal} className={classes.privacyLink}><b>Privacy Policy</b></Link>.
                    </Typography>
                </Alert>
            </Fade >
        </div >
    );
}

export default PrivacyAlert;