import { Badge, Container, Avatar, Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { pgwTokenRequest } from '../../../../store/Pgw/PgwAction';
import MpgsForm from '../MpgsForm/MpgsForm';
import PgwForm from '../PgwForm/PgwForm';

function Card(props) {
    let { paymentOptions, classes, selected, handleSelected, paymentData, isPanelSelected } = props
    let dispatch = useDispatch()

    return (
        !props ? < ></> :
            <Grid
                container
                item
                direction="column"
            >
                {/* <Container>
                    <Grid
                        container
                        item
                        spacing={1}
                        className={classes.item}
                    >
                        {
                            paymentOptions.tag ?
                                paymentOptions.options && Object.keys(paymentOptions.options).map((key) => {
                                    return paymentOptions.options[key].tag ?
                                        <Grid item className={classes.cards}>
                                            <Badge
                                                invisible={selected != key}
                                                badgeContent={'✓'}
                                                color="primary"
                                                className={classes.badge} >
                                                <ToggleButton
                                                    value={key}
                                                    selected={selected == key}
                                                    onChange={() => handleSelected(key, 'card')}
                                                    className={classes.toggleCard}
                                                >
                                                    <Avatar className={classes.avatar} src={paymentOptions.options[key].logo} />
                                                </ToggleButton>
                                            </Badge>
                                        </Grid>
                                        :
                                        ''
                                }) : ''

                        }
                    </Grid>
                </Container> */}

                {
                    paymentData.processor && paymentData.processor.name === 'mpgs' ?
                        <MpgsForm
                            payment={paymentData.data}
                            disabled={false}
                            handleOnSubmit={props.handleOnSubmit}
                            isPanelSelected={isPanelSelected}
                            cardLogo={props.cardLogo}
                            handleSelected={handleSelected}
                            cardValid={props.cardValid}
                            handleCardNumField={props.handleCardNumField} /> :
                        <PgwForm handleOnSubmit={props.handleOnSubmit}
                            disabled={false}
                            payment={paymentData.data}
                            isPanelSelected={isPanelSelected}
                            cardLogo={props.cardLogo}
                            handleSelected={handleSelected}
                            cardValid={props.cardValid}
                            handleCardNumField={props.handleCardNumField} 
                            handleFormChange={props.handleFormChange}/>
                }

            </Grid>
    );
}

export default Card;