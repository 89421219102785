import * as types from "./AuthTypes";

const INITIAL_STATE = {
  isLoading: false,
  error: {
    flag: false,
    msg: null
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_AUTH:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case types.GET_AUTH_FAILED:
      return {
        ...state,
        error: {
          flag: true,
          msg: 'auth problem'
        },
        isLoading: false,
      }
    default:
      return state;
  }
};