import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import BaseStatus from '../../../../components/BaseStatus/BaseStatus';
import ModalBase from '../../../../components/ModalBase/ModalBase';
import { requestAuth } from '../../../../store/Auth/AuthAction';
import { getPayment, updatePayment } from '../../../../store/Payment/PaymentAction';
import { getLocalPaymentId } from '../../../../utils/Auth';
import constant from '../../../../utils/Constants';
import { Prompt } from 'react-router'
import { CollectionsOutlined, TrainRounded } from '@material-ui/icons';
import { withRouter } from 'react-router'
import Loader from '../../../Loader/Loader';
import AcknowledgementSuccess from './AcknowledgementSuccess';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

function Success(props) {
    const payment = useSelector(state => state.payment.data)
    const dispatch = useDispatch()
    const query = useQuery()
    const paymentUpdate = useSelector(state => state.payment.updateData)
    const [redirect, setRedirect] = useState(null)
    const history = useHistory()
    const [isBackButtonClicked, setBackbuttonPress] = useState(false)
    // const [paymentId, setPaymentId] = useState(getLocalPaymentId())

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        setBackbuttonPress(true)
        window.history.pushState(null, null, window.location.pathname);
    }


    useEffect(() => {
        let paymentId = getLocalPaymentId()
        if (!paymentId) {
            if (process.env.REACT_APP_GET_PARAMS == 1) {
                paymentId = query.get('paymentId')
            }
        }
        if (!paymentUpdate) {
            dispatch(requestAuth({
                justAuth: true,
                paymentId: paymentId,
                checkDuplicate: false,
                executeUpdate: true,
                updateData: { paymentId: paymentId, status: constant.payment_success }
            }))
        }


        if (paymentUpdate) {
            if (paymentUpdate.status !== 'SUCCESS') {
                history.push('/status/failed')
            } else {
                const symbol = paymentUpdate.redirectUrl.success.includes('?') ? '&' : '?'
                setRedirect(`${paymentUpdate.redirectUrl.success}${symbol}paymentId=${paymentId}&status=SUCCESS&referenceNumber=${paymentUpdate.referenceNumber}&totalAmount=${paymentUpdate.totalAmount.value}&bankFee=${paymentUpdate.bankFee}`)
            }
        }

    }, [paymentUpdate])

    return (
        redirect ? <div>
            <AcknowledgementSuccess
                paymentUpdate={paymentUpdate}
                payment={payment}
                merchantLink={redirect && redirect}
                redirect={true}
                isBackButtonClicked={isBackButtonClicked}
                setBackbuttonPress={setBackbuttonPress}
            ></AcknowledgementSuccess>
        </div> :
            <Loader getAuth={false} loader_title={constant.loader_title} loader_body={constant.updating_payment_body} />
    );
}

export default Success;