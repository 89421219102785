import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import QrCode from '../containers/QrCode/QrCode'
import constants from '../utils/Constants'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

const webSocketURL = process.env.REACT_APP_WEBSOCKET_URL;

const WebsocketClient = props => {
    const token = useSelector(state => state.token)
    const history = useHistory()
    const [activeClient, setActiveClient] = useState({})
    const [activeToken, setActiveToken] = useState({})
    const [paymentId, setPaymentId] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')
    const [counter, setCounter] = useState(process.env.REACT_APP_SPP_TIMEOUT)

    const openWebsocket = (paymentId) => {
        const client = new W3CWebSocket(webSocketURL)
        client.onopen = () => {
            client.send(JSON.stringify({
                type: "message",
                msg: "QR Code Generated",
                paymentId: paymentId,
                accessToken: token.accessToken
            }));
            setActiveClient(client)
            setActiveToken(token.accessToken)
            setPaymentId(paymentId)
        };
    }

    const closeWebsocket = () => {
        sendMessage('Websocket Client Disconnected')
        activeClient.close()
    }

    const sendMessage = (message) => {
        if (activeClient && activeClient.readyState === WebSocket.OPEN) {
            activeClient.send(JSON.stringify({
                type: "message",
                msg: message,
                paymentId: paymentId,
                accessToken: activeToken
            }));
        }
    }

    const handlePaymentStatus = (paymentStatus) => {
        if (paymentStatus === constants.payment_success) {
            history.push(`/status/success?paymentId=${paymentId}`)
        } else {
            history.push(`/status/failed?paymentId=${paymentId}`)
        }
        sendMessage('Invalidate QR Code')
    }

    activeClient.onmessage = (message) => {
        const dataFromServer = JSON.parse(message.data);
        
        if (process.env.REACT_APP_ENVIRONMENT !== 'production') 
            console.log('Message from server: ', dataFromServer)

        if (dataFromServer.paymentStatus) {
            setPaymentStatus(dataFromServer.paymentStatus)
        }

        if (dataFromServer.msg === 'Payment Status') {
            if (dataFromServer.paymentStatus === constants.payment_failed && dataFromServer.from === 'CHECK' && counter <= 0) {
                handlePaymentStatus(constants.payment_failed)
            } else if (dataFromServer.paymentStatus === constants.payment_success || (dataFromServer.paymentStatus === constants.payment_failed && dataFromServer.from === 'WEBHOOK')) {
                handlePaymentStatus(dataFromServer.paymentStatus)
            }
        }

        if (dataFromServer.msg === 'QR Code Invalidated') {
            closeWebsocket()
        }
    };

    activeClient.onclose = () => {
        setActiveClient({})
        setActiveToken({})
    };

    window.onunload = e => {
        sendMessage('Invalidate QR Code')
    }

    useEffect(() => {
        if (!paymentStatus || (paymentStatus === constants.payment_failed && counter > 0)) {
            if (counter > 0) {
                setTimeout(() => setCounter(counter - 1), 1000);
            } else {
                handlePaymentStatus(constants.payment_failed)
            }
        }
    })
    
    useEffect(() => {
        if (counter % 5 === 0) {
            sendMessage('Inquire Transaction')
        }
    }, [counter])
    
    return (
        <QrCode
            openWebsocket={openWebsocket}
            sendMessage={sendMessage}
            counter={counter}
        />
    );
};

export default WebsocketClient;