import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Popover } from '@material-ui/core';
import contactUsLogo from '../../assets/icons/contactUsLogo.svg'
import emailLogo from '../../assets/icons/emailLogo.png'
import phoneLogo from '../../assets/icons/phoneLogo.png'
import poweredByBayadLogo from '../../assets/icons/poweredByBayad.png'
import constant from '../../utils/Constants';

const useStyles = makeStyles((theme) => ({

    root: {
        backgroundColor: '#FBFBFB',
        paddingTop: '6.5px',
        paddingBottom: '6.5px',
    },
    
    header: {
        display: "flex",
        margin: 'auto',
        maxWidth: '95%',
    },

    poweredBy: {
        verticalAlign: "middle",
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 5,
        marginRight: "auto",
        textAlign: "left",
        [theme.breakpoints.down("xs")]: {
            marginRight: 40,
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: 40,
        },
    },

    poweredByLogo: {
        verticalAlign: 'middle', 
        width: 121, 
        height: 25,
    },

    contactUs: {
        verticalAlign: "middle",
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: "auto",
        marginRight: 5,
        [theme.breakpoints.down("xs")]: {
            marginRight: 1,
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: 1,
        },
    },

    contactUsButton: {
        color: 'gray',
        textDecoration: 'none',
        fontSize: 14,
        letterSpacing: 0,
        height: 10,
        marginLeft: -5
    },

    contactUsContent: {
        width: 20, 
        height: 20,
        color: 'gray',
        textDecoration: 'none',
        margin: 10,
        verticalAlign: 'middle',
        whiteSpace: 'nowrap'
    },

}));

const Transaction = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleContactUsModal = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <Grid item className={classes.header} >
                <span className={classes.poweredBy}><img src={poweredByBayadLogo} className={classes.poweredByLogo}></img></span>
                <span className={classes.contactUs}><img src={contactUsLogo} style={{ verticalAlign: 'middle' }}></img> <Button className={classes.contactUsButton} onClick={handleContactUsModal}>Contact Us</Button></span>
            </Grid>
                   
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ marginLeft: 100, marginTop: 10 }}
            >
                <Typography style={{ margin: 10 }} sx={{ p: 2 }}>
                    <span className={classes.contactUsContent}><img className={classes.contactUsContent} src={phoneLogo}></img> {constant.contact_phone}</span>
                    <br></br>
                    <span className={classes.contactUsContent}><img className={classes.contactUsContent} src={emailLogo}></img> {constant.contact_email}</span>
                </Typography>
            </Popover>
        </div>
    );
}

export default Transaction;