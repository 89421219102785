import React from 'react';
import constants from '../../utils/Constants';
import {
    Typography,
    Grid,
    Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import DotLoader from '../../components/DotLoader/DotLoader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestAuth } from '../../store/Auth/AuthAction';
import { getSession, setSession, storePaymentId } from '../../utils/Auth';
import { useLocation } from "react-router-dom";
import bayadLogo from "../../assets/icons/bayadLogo.svg"
import { mpgsPay } from '../../store/Pgw/PgwAction';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        margin: "auto",
        textAlign: 'center',
        backgroundColor: "#dcf0fa"
    },

    spiel: {
        marginTop: 50
    },

    cardBody: {
        marginTop: 15,
        fontSize: 14,
        lineHeight: 1.5
    },

    title: {
        fontSize: 18,
        letterSpacing: 3,
        fontWeight: "bold",
        lineHeight: 1.5
    },
}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Loader = props => {
    const classes = useStyles();
    const query = useQuery();
    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.payment.data)


    useEffect(() => {
        if (query.get('q') == "execute") {
            dispatch(mpgsPay())
        }
    }, [])

    return (
        <div>
            <Grid container className={classes.root}>

                <Grid container item direction='column' justify='center' alignItems='center' className={classes.spiel} >
                    <Grid item>
                        <Typography className={classes.title} >{constants.loader_title}</Typography>
                    </Grid>
                    <Grid item>
                        <DotLoader />
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};

export default Loader;