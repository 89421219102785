import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import React from 'react'
import constant from '../../../../utils/Constants';
function CancelDialog(props) {
    let { classes, handleCloseModal, handleYesModal, modalOpen } = props
    return (
        <Dialog
            open={modalOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.dialog}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h4">
                    {constant.update_modal_header}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="body2">
                        {constant.update_modal_body1}
                    </Typography>
                    <Typography variant="body2">
                        {constant.update_modal_body2}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button variant="contained" onClick={handleCloseModal} className={classes.noButton} >
                    <Typography variant="subtitle2" >
                        {constant.update_modal_cancel}
                    </Typography>
                </Button>
                <Button variant="contained" color="primary" onClick={handleYesModal} className={classes.yesButton}>
                    <Typography variant="subtitle2" >
                        {constant.update_modal_confirm}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CancelDialog;