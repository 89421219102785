import { combineReducers } from "redux";
import auth from "../Auth/AuthReducer";
import payment from '../Payment/PaymentReducer'
import token from "../Token/TokenReducer";
import pgw from "../Pgw/PgwReducer";
import pgwData from "../PgwData/PgwDataReducer";
import duplicateData from "../DuplicateData/DuplicateDataReducer";

export default combineReducers({
    auth,
    token,
    payment,
    pgw,
    pgwData,
    duplicateData
});

