import axios from "axios"
import qs from "qs";
import api from "../../utils/Api";

export const paymayaToken = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${data.link}/payment-tokens`,
            data: {
                card: data.card
            },
            auth: {
                username: data.publicKey
            }
        })

        return res.data
    } catch (err) {
        throw err
    }
}

export const createPaymaya = async ({ data }) => {
    try {
        let res = await api({
            method: 'post',
            url: '/paymaya',
            data: data
        })

        return res.data
    } catch (err) {
        throw err
    }
}

export const createSession = async (data) => {
    try {
        let res = await api({
            method: 'post',
            url: '/mpgs/session',
            data: {
                "session": {
                    "authenticationLimit": 25
                },
                ...data
            }
        })
        return res.data
    } catch (err) {
        throw err
    }
}

export const updateForm = async (data) => {
    try {

        let res = await axios.post(`${process.env.REACT_APP_MPGS_FORM_URL}/${data.sessionId}`,
            qs.stringify({
                gatewayReturnUrl: 'https://google.com',
                gatewayCardNumber: data.number,
                gatewayCardSecurityCode: data.cvc,
                gatewayCardExpiryDateMonth: data.expMonth,
                gatewayCardExpiryDateYear: data.expYear
            }), {
            headers: {
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
            }
        })

        return res.data
    } catch (err) {
        throw err
    }
}

export const initiateAuthentication = async (data) => {
    try {
        let res = await api({
            method: 'put',
            url: `/mpgs/session/?paymentId=${data.paymentId}`,
            data: data.data,
        })

        return res.data
    } catch (err) {
        throw err
    }
}


export const authenticate = async (data) => {

    try {

        let res = await api({
            method: 'put',
            url: `/mpgs/session/?paymentId=${data.paymentId}&transactionId=${data.transactionId}`,
            data: data.data,
        })

        return res.data

    } catch (err) {
        throw err
    }
}

export const mpgsRedirect = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${data.url}`,
            data: {
                'cReq': data.value
            },
        })

        console.log(res.data)
        return res.data
    } catch (err) {
        throw err
    }
}

export const mpgsPay = async (data) => {
    try {
        let res = await api({
            method: 'put',
            url: `/mpgs/session/?paymentId=${data.paymentId}`,
            data: data.data
        })

        return res.data
    } catch (err) {
        throw err
    }
}