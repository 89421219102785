import axios from "axios";
import api from "../../utils/Api";
import { getSessionId } from "../../utils/Auth";


export const getPayment = async (paymentData) => {
    try {

        let res = await api({
            method: 'post',
            data: {
                paymentId: paymentData.paymentId,
                checkDuplicate: paymentData.checkDuplicate,
                sessionId: getSessionId()
            },
            url: `/getPayment`
        })

        return res.data
    } catch (e) {
        throw e
    }
}


export const updatePayment = async (paymentData) => {
    try {
        let body = {
            ...paymentData.data
        }

        let res = await axios({
            url: process.env.REACT_APP_BASE_URL + '/payments',
            data: body,
            method: 'put',
            headers: {
                Authorization: 'Bearer ' + paymentData.auth
            }
        })

        return res.data.data
    } catch (e) {
        throw e
    }
}

export const getProcessor = async (amount, paymentType, processor, paymentId) => {
    try {
        let data = {}
        if (paymentType === 'card') {
            data = {
                amount: amount,
                cardNum: processor,
                paymentId: paymentId
            }
        } else if (paymentType === 'wallet') {
            data = {
                amount: amount,
                wallet: processor,
                paymentId: paymentId
            }
        }

        let res = await api({
            url: `/processor`,
            data: data,
            method: 'post'
        })

        return res.data
    } catch (e) {
        throw e
    }
}

export const createPaymentWallet = async (wallet, paymentId, methodId, isMobile, emailAddress, ) => {
    try {

        let data = {
            paymentId,
            methodId,
            isMobile
        }

        if(emailAddress) data.emailAddress = emailAddress 

        if (process.env.REACT_APP_ENVIRONMENT !== 'production') 
            console.log('CREATE PAYMENT WALLET DATA: ', data);

        let url = ""

        switch (wallet) {
            case 'PaymayaWallet':
                url = '/paymayawallet'
                break;
            case 'GcashWallet':
                url = '/gcashwallet'
                break;
            case 'BayadWallet':
                url = '/bayadwallet'
                break;
            case 'ShopeePayWallet':
                url = '/shopeepaywallet'
                break;
            default:
                url = ''
                break;
        }

        let res = await api({
            url: url,
            data: data,
            method: 'post'
        })

        return res.data
    } catch (e) {
        throw e
    }
}

export const logCancelPaymentAttempt = async (paymentId, action) => {
    try {
        let data = {
            paymentId,
            action,
        }

        let res = await api({
            url: '/logs/cancel',
            data: data,
            method: 'post'
        })

        return res.data
    } catch (e) {
        throw e
    }
}

export const checkTransactionStatus = async (wallet, paymentId) => {
    try {

        let url = ""

        switch (wallet) {
            case 'ShopeePayWallet':
                url = `/shopeepaywallet/?pgwPaymentId=${paymentId}`
                break;
            default:
                url = ''
                break;
        }

        let res = await api({
            url: url,
            data: '',
            method: 'get'
        })

        return res.data
    } catch (e) {
        throw e
    }
}

export const invalidatePayment = async (wallet, paymentId, isMobile) => {
    try {

        let data = {
            isMobile,
        }

        let url = ""

        switch (wallet) {
            case 'ShopeePayWallet':
                url = `/shopeepaywallet/?pgwPaymentId=${paymentId}`
                break;
            default:
                url = ''
                break;
        }

        let res = await api({
            url: url,
            data: data,
            method: 'put'
        })

        return res.data
    } catch (e) {
        throw e
    }
}