import {
    Grid,
    makeStyles,
    Typography,
    Container,
    Tabs,
    Tab,
    CircularProgress,
    Link,
    Button,
    withStyles,
    Paper
} from '@material-ui/core';
import {
    TextField as formikTextField
} from 'formik-material-ui'
import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import jcbLogo from '../../assets/jcbLogo.svg'
import masterLogo from '../../assets/masterCardLogo.svg'
import visaLogo from '../../assets/visaLogo.svg'
import paymayaLogo from '../../assets/mayaLogo.png'
import bayadLogo from '../../assets/bayadLogo.png'
import gcashLogo from '../../assets/gcashLogo.png'
import shopeepayLogo from '../../assets/shopeepayLogo.png'
import cardLogo from '../../assets/cardLogo.svg'
import ewalletLogo from '../../assets/walletLogo.svg'
import otcLogo from '../../assets/otcLogo.svg'
import emailLogo from '../../assets/emailLogo.svg'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { cancelPayment, getProcessor, updatePayment, setProcessor } from '../../../../store/Payment/PaymentAction';
import { getLocalPaymentId } from '../../../../utils/Auth';
import constant from '../../../../utils/Constants';
import urlEncode from '../../../../utils/UrlEncode';
import TabPanel from '../../../../components/TabPanel/TabPanel';
import Card from './Card';
import Wallet from './Wallet';
import paymayaWalletDisabled from '../../assets/maya_coming_soon.png'
import bayadWalletDisabled from '../../assets/bayad_coming_soon.png'
import gcashWalletDisabled from '../../assets/gcash_coming_soon.png'
import shopeepayWalletDisabled from '../../assets/shopeepay_coming_soon.png'
import { redirectUrl } from '../../../../store/Auth/AuthAction';

const Accordion = styled((props) => (
    <MuiAccordion
        disableGutters elevation={0} square {...props}
    />
))(({ theme }) => ({
    '& .MuiCollapse-root': {
        backgroundColor: '#FCFCFC',
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
}
));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<AddIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        filter: 'invert(46%) sepia(84%) saturate(2575%) hue-rotate(349deg) brightness(98%) contrast(93%)',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        '& #accordionSectionTitle': {
            filter: 'invert(46%) sepia(84%) saturate(2575%) hue-rotate(349deg) brightness(98%) contrast(93%)',
        },
        '& #accordionLogo': {
            filter: 'invert(46%) sepia(84%) saturate(2575%) hue-rotate(349deg) brightness(98%) contrast(93%)',
        },
    },
}
));

const AccordionDetails = styled(MuiAccordionDetails)((
    { theme }) => ({
        // padding: theme.spacing(2),
    }
));

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: "center",
        maxWidth: 478,
        marginTop: 36,
        marginBottom: 24,
        marginLeft: 0,
        marginRight: 0,
        letterSpacing: '0.59px',
        padding: 0,
        [theme.breakpoints.down("xs")]: {
            maxWidth: '100%',
            marginTop: 30,
            marginBottom: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: '100%',
            marginTop: 30,
            marginBottom: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    item: {
        marginTop: 10,
    },
    avatar: {
        width: '100% !important',
        height: '100% !important',
        borderRadius: 'unset !important',
        // [theme.breakpoints.down("xs")]: {
        //     width: '65px !important',
        // }
    },
    cards: {
        // minHeight: 100,
    },

    title: {
        fontSize: 20,
        fontWeight: 700
    },

    badge: {
    },

    header: {
        fontSize: 18,
        color: "#0076bf",
        letterSpacing: 2,
        fontWeight: 600
    },

    iframe: {
        display: 'flex',
        width: '100%',
        height: '400px'
    },

    dialog: {
        textAlign: 'center'
    },

    dialogAction: {
        justifyContent: 'center'
    },

    noButton: {
        height: 42,
        width: 151,
        backgroundColor: '#9A9A9A'
    },

    yesButton: {
        height: 42,
        width: 151,
    },

    payLabel: {
        marginTop: 20,
        marginBottom: 20
    },

    tab: {
        width: '100%'
    },

    actions: {
        paddingTop: 40,
    },

    loader: {
        color: '#fafafa'
    },

    back: {
        cursor: 'pointer',
        textDecoration: 'none'
    },
    toggleWallet: {
        padding: 0,
        [theme.breakpoints.down("lg")]: {
            width: '80px !important',
            height: '58px !important',
        }
    },
    toggleCard: {
        [theme.breakpoints.down("lg")]: {
            width: '80px !important',
            height: '58px !important',
        }
    },
    accordionSection: {
        // minWidth: '100%',
        borderTop: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    },
    accordionSectionTop: {
        margin: 'auto',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        border: '1px solid #dbdbdb',
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#FCFCFC',
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
        },
    },
    accordionSectionBottom: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        border: '1px solid #dbdbdb',
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#FCFCFC',
            borderBottomLeftRadius: 7,
            borderBottomRightRadius: 7,
        },
    },
    accordionSectionTitle: {
        fontWeight: 'bold',
        letterSpacing: '0.59px',
        marginLeft: '1.25em',
        marginTop: 10,
        marginBottom: 10,
        fontColor: '#2C2C2C',
        fontSize: 15,
        [theme.breakpoints.down("xs")]: {
            fontSize: '1.2em',
            marginLeft: '1em',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    },
    accordionSectionTitleCardIcons: {
        width: 45,
        height: 30,
        marginLeft: '0.5em',
        marginTop: 6,
        marginRight: -10,
        [theme.breakpoints.down("xs")]: {
            width: 'auto',
            height: 24,
            marginTop: 'auto',
            marginLeft: '0.7em',
        },
    },
    paymentMethodTitle: {
        textAlign: 'left',
        letterSpacing: '0.78px',
    },
    paperContainer: {
        margin: 'auto',
        borderRadius: 7,
        // border: '1px solid #dbdbdb',
    },
    emailField: {
        fontSize: '0.9em',
        [theme.breakpoints.down("xs")]: {
            height: '0.1em',
            fontSize: 10,
        },
    },
    emailFieldPaperContainer: {
        margin: 'auto',
        padding: 20,
        borderRadius: 7,
        border: '1px solid #dbdbdb',
        backgroundColor: '#FCFCFC',
    },
    emailFieldLogoContainer: {
        display: 'flex',
        margin: 12,
    },
    emailFieldLogo: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    emailFieldLabel: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 20,
        fontWeight: 'bold',
        verticalAlign: 'middle',
        textAlign: 'left',
        fontSize: '1em',
        fontColor: '#2C2C2C',
        [theme.breakpoints.down("xs")]: {
            fontSize: '1.15em',
        },
    },
    paymentSelection: {
        [theme.breakpoints.down("xs")]: {
            maxWidth: '100%',
        },
    },
}));

const PayButton = withStyles((theme) => ({
    root: {
        backgroundColor: "#0076BF",
        '&:hover': {
            backgroundColor: "#0287d9",
        },
    },
}))(Button);

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PaymentMethod = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { handleModal, backModal } = props
    const payment = useSelector(state => state.payment)
    const paymentUpdate = useSelector(state => state.payment.updateData)
    const [expanded, setExpanded] = useState('')
    const [showEmailField, setShowEmailField] = useState(false)
    const [paymentLogo] = useState({
        Visa: visaLogo,
        Mastercard: masterLogo,
        Jcb: jcbLogo
    })
    const [walletLogo] = useState({
        paymayaWallet: paymayaLogo,
        bayadWallet: bayadLogo,
        gcashWallet: gcashLogo,
        shopeepayWallet: shopeepayLogo
    })
    const [walletLogoDisabled] = useState({
        paymayaWallet: paymayaWalletDisabled,
        bayadWallet: bayadWalletDisabled,
        gcashWallet: gcashWalletDisabled,
        shopeepayWallet: shopeepayWalletDisabled
    })
    const [paymentOptions, setPaymentOptions] = useState({
        tag: true
    })
    const [walletOptions, setWalletOptions] = useState({
        tag: true
    })

    const [initialForm, setInitialForm] = useState({
        emailAddress: ''
    })

    const [initialValidation, setInitialValidation] = useState({
        emailAddress: Yup.string().email('You must input a valid email format.'),
    })
    const validation = Yup.object(initialValidation)

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
        if (expanded !== false) {
            setShowEmailField(true)
        }
        props.setValue(panel === 'panelCards' ? 0 : 1);
        props.setSelected("")
        props.setCardLogo("")
        props.handleCardNumField('changePanel')
        dispatch(setProcessor(null));
    };

    useEffect(() => {
        if (payment.data) {
            let card = {}
            let wallet = {}
            if (payment.data.merchantPaymentOptions) {
                Object.keys(payment.data.merchantPaymentOptions.cards).map(key => {
                    card[key] = {
                        tag: payment.data.merchantPaymentOptions.cards[key],
                        logo: paymentLogo[key]
                    }
                });
                Object.keys(payment.data.merchantPaymentOptions.wallet).map(key => {
                    wallet[key] = {
                        tag: payment.data.merchantPaymentOptions.wallet[key],
                        logo: walletLogo[key],
                        disableLogo: walletLogoDisabled[key]
                    }
                });
            }

            setWalletOptions({ options: wallet, ...walletOptions })
            setPaymentOptions({ options: card, ...paymentOptions })
        }
    }, [payment]);

    useEffect(() => {
        console.log(backModal, 'backmodal')
        let url = ""
        if (paymentUpdate) {
            dispatch(redirectUrl(true))
            switch (paymentUpdate.status) {
                case constant.payment_canceled:
                    url = `${payment.data.redirectUrl.cancel}?paymentId=${getLocalPaymentId()}&status=CANCELLED&referenceNumber=${paymentUpdate.referenceNumber}&errorCode=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`
                    history.push('/redirect?' + urlEncode('q', url))
                    break
                case constant.payment_expired:
                    if (!paymentUpdate.sessionExpired) {
                        url = `${payment.data.redirectUrl.cancel}?paymentId=${getLocalPaymentId()}&status=EXPIRED&referenceNumber=${paymentUpdate.referenceNumber}&errorCode=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`
                        history.push('/redirect?' + urlEncode('q', url))
                    }
                    break
                default:
                    dispatch(redirectUrl(false))
                    break
            }
        }

    }, [paymentUpdate])

    return (
        <div>
            {/* <PrivacyAlert id='page-privacy-alert' handleModal={handleModal}></PrivacyAlert> */}
            <Container maxWidth="md" className={classes.root}>

                <Grid
                    container
                    direction="column"
                    // alignItems="center"
                    justify="center"
                    spacing={3}
                    md={12}
                    lg='auto'
                >

                    <Grid item>
                        <Typography className={classes.paymentMethodTitle} variant="h4">
                            {constant.payment_method_select_header}
                        </Typography>
                    </Grid>

                    <Grid item className={classes.paymentSelection}>

                        <Accordion className={classes.accordionSectionTop} value={props.value} index={0} expanded={expanded === 'panelCards'} onChange={handleAccordionChange('panelCards')}>
                            <AccordionSummary expandIcon={expanded === 'panelCards' ? <RemoveIcon /> : <AddIcon />} aria-controls="panelCardsdh-content" id="panelCardsdh-header">
                                <img src={cardLogo} id="accordionLogo"></img><Typography className={classes.accordionSectionTitle} id="accordionSectionTitle">CARDS</Typography>
                                {Object.entries(payment.data.merchantPaymentOptions.cards).map(([key, value]) => (
                                        value ?  <img src={paymentLogo[key]} className={classes.accordionSectionTitleCardIcons} /> : ''
                                    ))}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Card
                                    handleOnSubmit={props.handleOnCardSubmit}
                                    handleSelected={props.handleSelected}
                                    classes={classes}
                                    selected={props.selected}
                                    paymentOptions={paymentOptions}
                                    paymentData={payment}
                                    isPanelSelected={expanded}
                                    cardLogo={props.cardLogo}
                                    cardValid={props.cardValid}
                                    handleCardNumField={props.handleCardNumField}
                                    handleFormChange={props.handleFormChange}
                                > </Card>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className={classes.accordionSectionBottom} value={props.value} index={0} expanded={expanded === 'panelWallets'} onChange={handleAccordionChange('panelWallets')}>
                            <AccordionSummary expandIcon={expanded === 'panelWallets' ? <RemoveIcon /> : <AddIcon />} aria-controls="panelWalletsdh-content" id="panelWalletsdh-header">
                                <img src={ewalletLogo} id="accordionLogo"></img><Typography className={classes.accordionSectionTitle} id="accordionSectionTitle">E-WALLETS</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Wallet handleOnSubmit={props.handleOnWalletSubmit} handleSelected={props.handleSelected} classes={classes} selected={props.selected} paymentOptions={walletOptions} paymentData={payment} isPanelSelected={expanded}> </Wallet>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {
                        showEmailField && (payment.data.merchantCustomerInformation && payment.data.merchantCustomerInformation.emailField) ?
                            <>
                                <Grid item>
                                    <Paper className={classes.emailFieldPaperContainer} elevation={0}>
                                        <Formik
                                            initialValues={initialForm}
                                            initialTouched={{
                                                emailAddress: true,
                                            }}
                                            validationSchema={validation}
                                        >
                                            {({
                                                submitForm,
                                                setFieldValue,
                                                values,
                                                isValid
                                            }) => (
                                                <Form>
                                                    {
                                                        <>
                                                            <Grid container spacing={2}>
                                                                <Grid item className={classes.emailFieldLogoContainer}>
                                                                    <img src={emailLogo} className={classes.emailFieldLogo}></img><Typography className={classes.emailFieldLabel}>Enter your email if you want to receive an acknowledgement receipt.</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12}>
                                                                    <Field
                                                                        name='emailAddress'
                                                                        variant="outlined"
                                                                        component={formikTextField}
                                                                        value={values.emailAddress}
                                                                        placeholder="Email Address (Optional)"
                                                                        fullWidth
                                                                        InputProps={{ classes: { input: classes.emailField } }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {props.setEmailAdd(values.emailAddress)}
                                                    {props.setIsEmailValid(isValid)}
                                                </Form>
                                            )}
                                        </Formik>
                                    </Paper>
                                </Grid>
                            </>
                            : <> </>
                    }
                </Grid>
            </Container>
        </div>
    );
}

export default PaymentMethod;