import { Container, Grid, Card, makeStyles, Typography, Tooltip, Button, Snackbar, useMediaQuery, Popover } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router";
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import continueLogo from '../../../../components/assets/continueLogo.png'
import failedLogo from '../../../../components/assets/failed.png'
import copyIcon from '../../../../components/assets/icon-copy.svg'
import constant from '../../../../utils/Constants';
import urlEncode from "../../../../utils/UrlEncode";
import ModalBase from "../../../../components/ModalBase/ModalBase";
import successLogo from '../../../../components/assets/successLogo.png'
import { Alert } from "@material-ui/lab";
import Header from "../../../../components/Header/Header";
import { useDispatch } from "react-redux";
import { redirectUrl } from "../../../../store/Auth/AuthAction";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '95vh',
        // /margin: 'auto',
        textAlign: 'center',
    },
    headerContainer: {
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: 640,
    },
    failedLogo: {
        width: '100%',
        height: '100%'
    },
    continueLogo: {
        width: '85%',
        height: '85%'
    },
    errorBody: {
        background: '#F6F6F6 0% 0% no-repeat padding-box',
        marginLeft: 20,
        marginRight: 20,
        backgroundImage:'url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216px%22%20height%3D%228px%22%3E%3Cpolygon%20points%3D%220%2C0%208%2C8%2016%2C0%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216px%22%20height%3D%228px%22%3E%3Cpolygon%20points%3D%220%2C8%208%2C0%2016%2C8%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E")',
        backgroundPosition: 'top, bottom',
        backgroundRepeat: 'repeat-x',
        [theme.breakpoints.down("xs")]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    errorBodyContainer: {
        padding: 20,
        maxWidth: 600,
    },
    errorInfoContainer: {
        float: 'right !important',
    },
    errorMgs: {
        color: '#000000 !important',
    },
    errorInfo: {
        whiteSpace: 'nowrap',
        font: 'normal normal normal 14px/30px Poppins',
        fontWeight: 'bold',
        letterSpacing: '0.69px',
        [theme.breakpoints.down("xs")]: {
            whiteSpace: 'normal !important',
            fontSize: 12,
        }

    },
    errorHeaderInfo: {
        // whiteSpace: 'break-word',
        wordWrap: "break-word",
        font: 'normal normal normal 12px/22px Poppins',
        letterSpacing: '0.69px',
        fontWeight: 'bold',
    },
    copyIconContainer: {
        height: "100%",
    },
    copyIconItem: {
        textAlign: 'left !important'
    },
    copyIcon: {
        cursor: 'pointer',
        height: "15px",
        marginLeft: 5,
    },
    footerContainer: {
        paddingTop: 30,
    },
    btnAction: {
        letterSpacing: '0.69px',
        font: 'normal normal normal 14px/21px Poppins',
        width: '224px',
        height: '42px',
        borderRadius: '21px',
        borderColor: '#0077C0',
    },
    btnViewDetais: {
        letterSpacing: '0.69px',
        font: 'normal normal bold 14px/21px Poppins',
        // width: '224px',
        // height: '42px',
        borderRadius: '21px',
        marginTop: -15,
    },
}))

const AcknowledgementFailed = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const [openIconCopy, setOpenIconCopy] = useState(false);
    const [redirectOption, setRedirectOption] = useState(true)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();

    const onClickRedirect = useCallback(() => {
        dispatch(redirectUrl(true))
        history.push(`/redirect?${urlEncode('q', props.merchantLink)}&exit=true`)
    })

    useEffect(() => {
        if (process.env.REACT_APP_MERCHANT_REDIRECT == 1) {
            if (window !== window.parent) {
                setRedirectOption(false)
            }
        }

    }, [])

    const handleIconCopyClickReferenceNumber = () => {
        setOpenIconCopy(true);
        if (props.paymentUpdate) {
            const refNo = props.paymentUpdate.referenceNumber
            navigator.clipboard.writeText(refNo)
        }
    };

    const handleIconCopyClickErrorCode = () => {
        setOpenIconCopy(true);
        if (props.paymentUpdate) {
            const ErrorCode = props.paymentUpdate.error.code
            navigator.clipboard.writeText(ErrorCode)
        }
    };

    const handleIconCopyClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenIconCopy(false);
    };
 
    const errorMessage = () => {
        if( props.paymentUpdate){
            switch(props.paymentUpdate.error.code){
                case '60007':
                    return <Typography className={classes.errorHeaderInfo}>{constant.status_failed_invalid_card_security_code}</Typography>;
                case '60008':
                    return <Typography className={classes.errorHeaderInfo}>{constant.status_failed_invalid_authorization}</Typography>;
                case '60009':
                        return <Typography className={classes.errorHeaderInfo}>{constant.status_failed_qr_session_expired}</Typography>;
                case '60010':
                    return <Typography className={classes.errorHeaderInfo}>{constant.status_failed_declined_payment}</Typography>;
                case '60012':
                    return <Typography className={classes.errorHeaderInfo}>{constant.status_failed_insuffucuent_funds}</Typography>;
                case '60015':
                    return <Typography className={classes.errorHeaderInfo}>{constant.status_failed_declined_payment}</Typography>;
                default:
                    return <><Typography className={classes.errorHeaderInfo}>{constant.status_failed_title1}</Typography> 
                             <Typography className={classes.errorHeaderInfo}>{constant.status_failed_title2}</Typography> </>; 
            };
        }
    }

    const [show, setShow] = useState(false);

    return (
        <Container class='containerBg'>
            {
                !matches ? <Header/> : <></>
            }
            <Grid
                container
                direction='column'
                justify='space-around'
                alignItems='center'
                className={classes.root}
            >
                <Grid item>
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                    >
                        <Grid item>
                            <Grid
                                container
                                direction='column'
                                justify='center'
                                alignItems='center'
                                spacing={2}
                                className={classes.headerContainer}
                            >
                                <Grid item>
                                    <img className={props.expiredSession ? classes.continueLogo : classes.failedLogo} src={props.expiredSession ? continueLogo : failedLogo}></img>
                                </Grid>
                                <Grid item>
                                    <Typography variant='h5'>
                                        {constant.status_failed_header1}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                        {errorMessage()}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            
                        </Grid>
                        <Grid item>
                            { (redirectOption) ?
                                <Grid
                                    container
                                    direction='column'
                                    justify='center'
                                    alignItems='center'
                                    spacing={3}
                                    className={classes.footerContainer}
                                >
                                    <Grid item id="grid-item-content-body">
                                        {!show && <Button color="primary" className={classes.btnViewDetais} id="button-show-details" onClick={() => setShow(prev => !prev)} >
                                            {props.redirectLabel ? props.redirectLabel : constant.status_details_text}
                                        </Button>}
                                        {show && <Grid item className={classes.errorBody}>
                                            <Grid
                                                container
                                                
                                                className={classes.errorBodyContainer}
                                                direction='column'
                                                justify='center'
                                                alignItems='center'
                                            >
                                                {props.paymentUpdate ?
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="row" spacing={1}
                                                            alignItems="start"
                                                            className={classes.errorInfoContainer}
                                                        > 
                                                            <Grid item xs={6}>
                                                                <Typography align='left'  className={classes.errorInfo}>Status: </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {/* <Typography align='right'  className={classes.errorInfo}>{props.title}
                                                                </Typography> */}
                                                                <Typography align='right'  className={classes.errorInfo}>Failed
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5} >
                                                                <Typography align='left' className={classes.errorInfo}>{constant.status_failed_datetime} </Typography>
                                                            </Grid>
                                                            <Grid item xs={7} >
                                                                <Typography align='right' className={classes.errorInfo}>{props.paymentUpdate && moment(props.paymentUpdate.updatedAt).format('LLL')}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={3} >
                                                                <Typography align='left' className={classes.errorInfo}> Ref. No.: </Typography>
                                                            </Grid>
                                                            <Grid item xs={9} >
                                                                <Typography align='right' className={classes.errorInfo}>{props.paymentUpdate.referenceNumber}
                                                                    <Tooltip title="Copy" placement="right">
                                                                        <img className={classes.copyIcon} onClick={handleIconCopyClickReferenceNumber} src={copyIcon}></img>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <Typography align='left' className={classes.errorInfo}> {constant.status_failed_error_code}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <Typography align='right' className={classes.errorInfo}>{props.paymentUpdate.error.code}
                                                                    <Tooltip title="Copy" placement="right">
                                                                        <img className={classes.copyIcon} onClick={handleIconCopyClickErrorCode} src={copyIcon}></img>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Grid>
                                                            <Snackbar
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center'
                                                                }}
                                                                open={openIconCopy}
                                                                autoHideDuration={3000}
                                                                onClose={handleIconCopyClose}>
                                                                <Alert onClose={handleIconCopyClose} severity="info" sx={{ width: '100%' }}>
                                                                    {constant.status_failed_copy_code}
                                                                </Alert>
                                                            </Snackbar>
                                                        </Grid>
                                                    </Grid>
                                                    : <></>}
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                    <Grid item id="grid-item-back">
                                        <Button color="primary" variant='outlined' className={classes.btnAction} id="button-back" onClick={() => onClickRedirect()} >
                                            {props.redirectLabel ? props.redirectLabel : constant.status_redirect_text}
                                        </Button>
                                    </Grid>
                                </Grid>: <></>
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <ModalBase open={props.isBackButtonClicked} setModalOpen={props.setBackbuttonPress} handleYesModal={onClickRedirect} logo={successLogo} actionText={constant.from_end_action_text} bodyText={constant.from_end_body_text} headerText={constant.from_end_header_text}></ModalBase>
        </Container>
    )
}

export default AcknowledgementFailed;