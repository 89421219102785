import React, { useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    makeStyles,
    Slide
} from "@material-ui/core";
import constants from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import urlEncode from '../../utils/UrlEncode';
import { getLocalPaymentId } from "../../utils/Auth";
import errorLogo from '../assets/errorLogo.png'
import { redirectUrl } from "../../store/Auth/AuthAction";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: 30,
        borderRadius: 8,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 16,
            marginRight: 16,
        },
    },
    btnAction: {
        letterSpacing: '0.69px',
        font: 'normal normal normal 14px/21px Poppins',
        width: '224px',
        height: '42px',
        borderRadius: '21px',
        textTransform: 'none',
    },
    dialogAction: {
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 20
    },
    dialogTitle: {
        justifyContent: 'center',
        textAlign: 'center',
        font: 'normal normal 600 22px/26px Poppins',
        fontSize: '22px',
        letterSpacing: '1.08px',
        color: '#373D3F',
    },
    dialogTitleText: {
        color: '#373D3F',
        [theme.breakpoints.down("xs")]: {
            marginTop: 5,
            fontSize: '20px',
        },
    },
    dialogContent: {
        justifyContent: 'center',
        textAlign: 'center',
        font: 'normal normal normal 14px/21px Poppins',
        fontSize: '14px',
        letterSpacing: '0.69px',
        color: '#58595B',
    },
    dialogContentText: {
        fontWeight : 600,
        [theme.breakpoints.down("xs")]: {
            color: '#5a5a5a',
            fontSize: '14px',
        },
    },
    imgLogo: {
        paddingBottom: 20
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalStatusValidation = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const auth = useSelector(state => state.auth)
    const payment = useSelector(state => state.payment)
    const pgw = useSelector(state => state.pgw)
    const dispatch = useDispatch();

    const handleRedirect = () => {
        let code = pgw.error.flag ? pgw.error.data.code : payment.error.msg.data.code
        let errMsg = pgw.error.flag ? pgw.error.data.message : payment.error.msg.data.message
        props.setOpen(false)
        let url = `${payment.data.redirectUrl.cancel}?paymentId=${getLocalPaymentId()}&status=EXPIRED&referenceNumber=${payment.data.referenceNumber}&errorCode=${code}&errorMessage=${errMsg}`
        dispatch(redirectUrl(true)) 
        history.push('/redirect?' + urlEncode('q', url))
    }

    useEffect(() => {
        window.addEventListener('popstate', onBackButtonEvent)
    }, []);

    const onBackButtonEvent = (e) => {
        props.setOpen(false)
    }

    return (
        <>
            <Dialog
                open={props.open}
                classes={{ paper: classes.dialog }}
                TransitionComponent={Transition}
                maxWidth={'sm'}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <img src={errorLogo} className={classes.imgLogo}></img>
                    <Typography variant='h3' className={classes.dialogTitleText}>
                        {constants.expired_header}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography variant='h6' className={classes.dialogContentText}>{constants.expired_body}</Typography>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button disabled={auth.isLoading} variant='contained' color="primary" className={classes.btnAction} onClick={handleRedirect} >
                        {constants.expired_redirect_text}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalStatusValidation;