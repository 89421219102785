import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';
import PaymentSummary from './components/PaymentSummary/PaymentSummary';
import PaymentMethod from './components/PaymentMethod/PaymentMethod';
import PayButton from './components/PayButton/PayButton';
import { Container, Divider, Typography, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Popover, useMediaQuery, withStyles, CircularProgress } from '@material-ui/core';
import visaLogo from '../../assets/icons/visaLogo.svg'
import jcbLogo from '../../assets/icons/jcbLogo.svg'
import masterCardLogo from '../../assets/icons/masterCardLogo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentWallet, getPayment, getProcessor, setProcessor, updatePayment, logCancelAttempt } from '../../store/Payment/PaymentAction';
import { getOpened, getLocalPaymentId, getSessionId, removeOpened, setOpenedTrue, setSessionId, clearSessionId, getLocalToken } from '../../utils/Auth';
import { Redirect, useLocation } from 'react-router';
import PrivacyModal from '../../components/PivacyModal/PrivacyModal';
import SnackBarAlert from '../../components/SnackBarAlert/SnackBarAlert';
import { Link } from 'react-router-dom';
import BlockModal from '../../components/BlockModal/BlockModal';
import CancelDialog from './components/PaymentMethod/CancelDialog';
import ModalBase from '../../components/ModalBase/ModalBase';
import ExitSurveyModal from '../../components/ExitSurveyModal/ExitSurveyModal';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import failedLogo from '../../components/assets/failedLogo.svg'
import { GET_AUTH_SUCCESS } from '../../store/Auth/AuthTypes';
import DuplicatePromptModal from '../../components/DuplicatePromptModal/DuplicatePromptModal';
import constant from '../../utils/Constants';
import { pgwTokenRequest } from '../../store/Pgw/PgwAction';
import { isMobile } from 'react-device-detect';
import ModalStatusValidation from '../../components/ModalStatusValidation/ModalStatusValidation';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '91vh',
        margin: "auto",
        maxWidth: '62rem',
        [theme.breakpoints.only("md")]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.only("sm")]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: '95.5vh',
            maxWidth: '90%',
        },
    },

    paymentMethod: {
        display: 'flex',
        justifyContent: 'center'
    },

    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#f3f3f3',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        marginTop: isMobile ? '6em' : -5,
        marginBottom: 70,
        [theme.breakpoints.down("xs")]: {
            margin: 'auto',
        },
        [theme.breakpoints.down("sm")]: {
            margin: 'auto'
        },
    },

    privacyNoticeBrowser: {
        paddingLeft: 25,
        paddingRight: 25,
    },

    centerTitle: {
        textAlign: 'center',
        marginTop: 25
    },

    actionContainer: {
        maxWidth: '30%',
        paddingTop: 25,
        paddingBottom: 25,
        margin: 'auto'
    },

    payButtonSectionContainer: {
        padding: 0,
    },

    buttonContainer: {
        margin: 'auto',
        [theme.breakpoints.down("xs")]: {
            marginBottom: 40,
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: 40,
        },
    },

    back: {
        cursor: 'pointer',
        textDecoration: 'none'
    },

    backButton: {
        marginBottom: 10,
        justifyContent: 'right',
    }

}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
const Transaction = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const payment = useSelector(state => state.payment)
    const duplicateData = useSelector(state => state.duplicateData)
    const accessToken = useSelector(state => state.token.accessToken)
    // const formValues = useSelector(state => state.payment.formValues)
    const isValid = useSelector(state => state.payment.formIsValid)
    const processor = useSelector(state => state.payment.processor)
    const pgw = useSelector(state => state.pgw)
    const [paymentDetails, setPaymentDetails] = useState({})
    const [privacyModal, setPrivacyModal] = useState(false)
    const [privacyModalTabValue, setPrivacyModalTabValue] = useState(0)
    const [backModal, setBackModal] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [errorShown, setErrorShown] = useState(false)
    const [openModalDuplicate, setModalPaymentDuplicate] = useState(false)
    const [openExpiredModal, setOpenExpiredModal] = useState(false)
    const [ignoreModalDuplicate, setIgnoreModalDuplicate] = useState(false)
    const [value, setValue] = useState(0);
    const [selected, setSelected] = useState("");
    const [emailAdd, setEmailAdd] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState('0000');
    const [cardLogo, setCardLogo] = useState();
    const [cardValid, setCardValid] = useState(false);
    const [resetBankFee, setResetBankFee] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [proceedPayment, setProceedPayment] = useState(false)
    const [surveyModalOpen, setSurveyModalOpen] = useState(false)
    const cookieToken = getLocalToken()
    const theme = useTheme();
    const matches_xs = useMediaQuery(theme.breakpoints.down('xs'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', onBackButtonEvent)
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent)
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault()
        setSurveyModalOpen(true)
        window.history.pushState(null, null, window.location.pathname)
        // dispatch(updatePayment({ paymentId: getLocalPaymentId() }, constant.payment_canceled, getSessionId(), null));
        // clearSessionId();
        // props.history.goBack()        
    }

    const handleErrorClose = () => {
        setErrorOpen(!errorOpen)
    }
    const handlePrivacyModal = (value) => {
        setPrivacyModal(!privacyModal)
        if (value === 'TC') {
            setPrivacyModalTabValue(0)
        }
        else if (value === 'Privacy') {
            setPrivacyModalTabValue(1)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelected("")
        dispatch(setProcessor(null));
    };

    const handleCardNumField = (value) => {
        if (value === 'changePanel') {
            setResetBankFee(true)
        } else {
            handleSelected('', 'card', value)
        }
    }

    const handleSelected = (newSelected, type, cardNum) => {
        if (type === 'card') {
            if (cardNum) {
                dispatch(getProcessor(payment.data.totalAmount.value, type, cardNum, getLocalPaymentId()))
            } else {
                setSelected('')
                dispatch(setProcessor(null))
                setCardLogo('')
            }
        } else {
            dispatch(getProcessor(payment.data.totalAmount.value, type, newSelected, getLocalPaymentId()))
            setSelected(newSelected)
        }
        setResetBankFee(false)
    };

    const handleCardLogo = (selectedCard) => {
        let cardLogo = ''
        let isCardValid = false

        switch (selectedCard) {
            case 'visaLogo':
                cardLogo = visaLogo
                isCardValid = true
                break
            case 'masterCardLogo':
                cardLogo = masterCardLogo
                isCardValid = true
                break
            case 'jcbLogo':
                cardLogo = jcbLogo
                isCardValid = true
                break
            default:
                cardLogo = ''
                isCardValid = false
                break
        }

        setCardLogo(cardLogo)
        setCardValid(isCardValid)

    }

    const handleCancel = () => {
        // setModalOpen(true)
        setSurveyModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleFormChange = (values, isValid) => {
        setFormValues(values)
        setIsFormValid(isValid)
    }

    useEffect(() => {
        if (!getSessionId()) {
            setSessionId()
            dispatch(getPayment(getLocalPaymentId(), false, true));
        } else {
            dispatch(getPayment(getLocalPaymentId(), false, true));
        }
    }, [])

    useEffect(() => {
        if (payment.data) {
            setPaymentDetails(payment.data)
            if (payment.data.pgwTokenId !== null) {
                setBackModal(true)
            } else {
                if (!ignoreModalDuplicate && duplicateData.hasDuplicate) {
                    setModalPaymentDuplicate(duplicateData.enables)
                }
            }
        }
        if (payment.error.flag && payment.error.msg.data.code !== 50005) {
            if (payment.error.status !== 422) {
                props.history.push(`/404`)
            }
        }
    }, [payment, duplicateData])

    useEffect(() => {
        if (payment.processor && value === 0) {
            setSelected(payment.processor.paymentMethodName)
            handleCardLogo(payment.processor.icon)
        }
    }, [payment])

    useEffect(() => {
        let emailValid = payment.data && payment.data.merchantCustomerInformation.emailField ? isEmailValid : true
        if (query.get('alertMessage') && !errorShown) {
            setErrorMessage(query.get('alertMessage'))
            setErrorOpen(!errorOpen)
            setErrorShown(true)
        }

        if (!value) {
            setDisabled(isFormValid ? (emailValid ? false : true) : true)
        } else {
            setDisabled(processor && !resetBankFee ? (emailValid ? false : true) : true)
        }
    })

    useEffect(() => {
        if (accessToken !== cookieToken) {
            dispatch({
                type: GET_AUTH_SUCCESS,
                payload: cookieToken,
            })
        }
    }, [cookieToken])


    const handleYesOnClick = () => {
        setModalPaymentDuplicate(false)
        setIgnoreModalDuplicate(true)
    }

    const handleBackOnClick = () => {
        dispatch(updatePayment({ paymentId: getLocalPaymentId() }, constant.payment_canceled, getSessionId(), null));
    }

    const handleOnCardSubmit = () => {
        setProceedPayment(true)
        let values = formValues
        let expDate = values.expiryDate.split('/')
        if (selected) {
            setTimeout(() => {
                let data = {
                    number: values.cardNumber.replace(/\s/g, ''),
                    expMonth: expDate[0],
                    expYear: `20${expDate[1]}`,
                    cvc: values.cvc,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    isMobile: isMobile,
                }

                if (payment.data.merchantCustomerInformation.emailField) 
                    if (emailAdd) data.emailAddress = emailAdd

                if (payment.data.merchantCustomerInformation.billingAddress) {
                    data = {
                        ...data,
                        billingAddress: {
                            line1: values.line1,
                            line2: values.line2,
                            city: values.city,
                            state: values.state,
                            zipCode: values.zipCode,
                            country: values.country
                        }
                    }
                }

                if (process.env.REACT_APP_ENVIRONMENT !== 'production') 
                    console.log('DATA CARD: ', data);
                
                dispatch(pgwTokenRequest(data, payment.processor.name))
            }, 100);
        }
    }

    useEffect(() => {
        if ((pgw.error.flag && pgw.error.data.code === 50005) || (payment.error.flag && payment.error.msg.data.code === 50005)) {
            setOpenExpiredModal(true)
        }
    }, [pgw,payment])

    const handleOnWalletSubmit = (values) => {
        setProceedPayment(true)
        const wallet = payment.processor.paymentMethodName
        const methodId = payment.processor.paymentMethodId
        const paymentId = payment.data.paymentId
        dispatch(createPaymentWallet(wallet, paymentId, methodId, emailAdd, isMobile))
    };

    const payButton = () => {
        return (
            <>
                <Container maxWidth="lg" className={classes.payButtonSectionContainer}>
                    <Grid container xs={12} sm={12} md={12} className={classes.backButton}>
                        <Typography variant='body1' class='backButton'>
                            <Link to='/transaction' onClick={handleCancel} className={classes.back}>
                                {constant.return_to}{payment.data.merchantLinks && payment.data.merchantLinks.displayName} page
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} className={classes.buttonContainer}>
                        <PayButton
                            handleSubmit={value ? handleOnWalletSubmit : handleOnCardSubmit}
                            paymentMethod={value ? "wallet" : "card"}
                            totalAmount={totalAmount}
                            disabled={disabled}
                        >
                        </PayButton>
                    </Grid>
                    <CancelDialog modalOpen={modalOpen} classes={classes} handleYesModal={handleBackOnClick} handleCloseModal={handleCloseModal}></CancelDialog>
                    <ModalBase open={backModal} handleYesModal={handleBackOnClick} logo={failedLogo} actionText={constant.from_otp_action_text} bodyText={constant.from_otp_body_text} headerText={constant.from_otp_header_text}></ModalBase>
                </Container>
            </>
        );
    }

    // window.onbeforeunload = function() {
    //     console.log('about to exit')
    //     if (!proceedPayment) {         
    //         dispatch(logCancelAttempt(payment.data.paymentId, 'cancel_attempt'))
    //         return 'Are you sure you want to leave?'
    //     }
    // }

    // window.onunload = e => {
    //     console.log('page closed')
    //     if (!proceedPayment) {
    //         dispatch(logCancelAttempt(payment.data.paymentId, 'page_closed'))
    //     }
    // }

    // useEffect(() => {
        // window.onbeforeunload = e => {
        //     if (!proceedPayment) {
        //         console.log('about to exit')
        //         dispatch(logCancelAttempt(payment.data.paymentId, 'cancel_attempt'))
        //         // e.preventDefault()
        //         // return e.returnValue = "Are you sure you want to exit?"
        //         return 'testing'
        //     }
        // }
        // window.onunload = e => {
        //     if (!proceedPayment) {
        //         console.log('page closed')
        //         dispatch(logCancelAttempt(payment.data.paymentId, 'page_closed'))
        //     }
        // }
        // window.onpopstate = e => {
        //     console.log('pressed back button')
        //     // alert("You message");
        //     // dispatch(logCancelAttempt(payment.data.paymentId))
        //     // e.preventDefault()
        //     // return e.returnValue = ''
        // }
    // })

    return (
        !payment.data ? <></> :
            <div>
                {
                    !matches_xs ? <Header/> : <></>
                }

                <Grid
                    container
                    component="main"
                    className={classes.root}
                    direction="row-reverse"
                >
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.paper}>
                        <PaymentSummary
                            setTotalAmount={setTotalAmount}
                            resetBankFee={resetBankFee}
                            handleModal={() => handlePrivacyModal('Privacy')}
                        />
                        {!matches_xs && !matches_sm ? payButton() : <></>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PaymentMethod
                            handleOnCardSubmit={handleOnCardSubmit}
                            handleOnWalletSubmit={handleOnWalletSubmit}
                            handleModal={() => handlePrivacyModal('Privacy')}
                            backModal={backModal}
                            // handleChange={handleChange}
                            selected={selected}
                            setSelected={setSelected}
                            handleSelected={handleSelected}
                            value={value}
                            setValue={setValue}
                            setEmailAdd={setEmailAdd}
                            setIsEmailValid={setIsEmailValid}
                            cardLogo={cardLogo}
                            setCardLogo={setCardLogo}
                            cardValid={cardValid}
                            handleCardNumField={handleCardNumField}
                            handleFormChange={handleFormChange}
                        />
                        {matches_xs || matches_sm ? payButton() : <></>}
                    </Grid>
                    <PrivacyModal open={privacyModal} tabValue={privacyModalTabValue} handleModal={handlePrivacyModal}></PrivacyModal>
                    <SnackBarAlert open={errorOpen} message={errorMessage} type={'error'} handleClose={handleErrorClose}></SnackBarAlert>
                </Grid >
                <Footer/>
                <BlockModal open={paymentDetails && paymentDetails.showMultipleWindowWarning} />
                <DuplicatePromptModal
                    open={openModalDuplicate}
                    handleButtonModal1={handleYesOnClick}
                    handleButtonModal2={handleBackOnClick}></DuplicatePromptModal>
                <ExitSurveyModal open={surveyModalOpen} setSurveyModalOpen={setSurveyModalOpen} handleYesModal={handleBackOnClick}></ExitSurveyModal>
                <ModalStatusValidation open={openExpiredModal} setOpen={setOpenExpiredModal}></ModalStatusValidation>
            </div >
    );
}

export default Transaction;