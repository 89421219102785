import {
    Grid,
    InputAdornment,
    makeStyles,
    Typography,
    withStyles,
    TextField,
    Popover,
    Button,
    IconButton,
} from '@material-ui/core';
import { Info, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState, useRef } from 'react';
import {
    TextField as formikTextField
} from 'formik-material-ui'
import { useDispatch, useSelector } from 'react-redux';
import { pgwTokenRequest } from '../../../../store/Pgw/PgwAction';
import InputMask from "react-input-mask";
import * as Yup from "yup";
import Loader from '../Loader/Loader'
import { setFormValues } from '../../../../store/Payment/PaymentAction'

import { Formik, Field } from 'formik';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        // marginTop: 50,
        marginBottom: 23
    },
    label: {
        padding: theme.spacing(1), //grid padding
    },
    adjusted: {
        padding: theme.spacing(1), //grid padding
        minHeight: 45
    },
    actions: {
        paddingTop: theme.spacing(3),
    },

    loader: {
        color: '#fafafa'
    },

    back: {
        cursor: 'pointer',
        textDecoration: 'none'
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverIcon: {
        color: '#909090',
        [theme.breakpoints.down("xs")]: {
            width: 15,
            marginLeft: -3,
        },
    },
    paper: {
        padding: theme.spacing(1.5),
        backgroundColor: '#0076bf'
    },
    cardIcons: {
        width: 45,
        height: 30,
        [theme.breakpoints.down("xs")]: {
            width: 40,
            height: 25,
        },
    },
    cardFormFields: {
        [theme.breakpoints.down("xs")]: {
            height: '0.1em',
            fontSize: 10,
        },
    },
    maskButton: {
        cursor: 'pointer',
        color: '#8D8D8D',
        width: 28,
        height: 28,
        marginLeft: 4,
        [theme.breakpoints.down("xs")]: {
            width: 19,
            height: 19,
            marginRight: -4,
        },
    },

}));

const MpgsForm = (props) => {
    const { payment, disabled, isPanelSelected } = props
    const [showCardDetails, setShowCardDetails] = useState(true)
    const [showCvvDetails, setShowCvvDetails] = useState(true)
    const classes = useStyles()
    const dispatch = useDispatch()
    const formRef = useRef()
    const pgw = useSelector(state => state.pgw)
    const pgwData = useSelector(state => state.pgwData)
    const paymentProcessor = useSelector(state => state.payment.processor)
    const [minimumYear, setMinimumYear] = useState(0)

    const [initialForm, setInitialForm] = useState({
        gatewayCardNumber: '',
        gatewayCardSecurityCode: '',
        gatewayCardExpiryDateMonth: '',
        gatewayCardExpiryDateYear: '',
        firstName: '',
        lastName: '',
        isCardValid: false,
    })
    const [initialValidation, setInitialValidation] = useState({
        gatewayCardNumber: Yup.string().required('You missed this! Fill in the required details to proceed.').min(19, "You missed this! Fill in the required details to proceed.").max(19, 'You missed this! Fill in the required details to proceed.')
            .test(
                "card-valid-check",
                "Invalid card scheme. We accept Visa, Mastercard, and JCB only.",
                value => {
                    handleCheckCardScheme(value)
                    return initialForm.isCardValid
                }
            ),
        gatewayCardSecurityCode: Yup.string().min(3, 'You missed this! Fill in the required details to proceed.').max(4, 'Invalid'),
        gatewayCardExpiryDateMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').max(12, 'It appears that your card is expired. Please use a different card.'),
        gatewayCardExpiryDateYear: Yup.number().required('You missed this! Fill in the required details to proceed.').max(99, 'It appears that your card is expired. Please use a different card.'),
        firstName: Yup.string().required('You missed this! Fill in the required details to proceed.'),
        lastName: Yup.string().required('You missed this! Fill in the required details to proceed.'),
    })
    const [pgwSession, setPgwSession] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const validation = Yup.object(initialValidation)

    useEffect(() => {
        let date = new Date().getFullYear().toString()
        date = date.substring(2)
        setInitialValidation({
            ...initialValidation,
            gatewayCardExpiryDateYear: Yup.number().required('You missed this! Fill in the required details to proceed.').min(date, 'It appears that your card is expired. Please use a different card.').max(99, 'It appears that your card is expired. Please use a different card.'),
        })
        setMinimumYear(date)

    }, [])

    const handleDateChange = (e, setFieldValue, setFieldError, values) => {
        let year = e.target.value
        let month = values.gatewayCardExpiryDateMonth

        setFieldValue('gatewayCardExpiryDateYear', year)
        if (year === minimumYear) {
            let month = new Date().getMonth() + 1
            setInitialValidation({
                ...initialValidation,
                gatewayCardExpiryDateMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').min(month.toString(), 'It appears that your card is expired. Please use a different card.').max(12, 'It appears that your card is expired. Please use a different card.')
            })
        } else {
            setInitialValidation({
                ...initialValidation,
                gatewayCardExpiryDateMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').max(12, 'It appears that your card is expired. Please use a different card.')
            })
        }
    }

    const handleDateTouched = (errors, setFieldTouched) => {
        setFieldTouched('gatewayCardExpiryDateMonth', true)
        setFieldTouched('gatewayCardExpiryDateYear', true)
    }


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const PayButton = withStyles((theme) => ({
        root: {
            backgroundColor: "#0076BF",
            '&:hover': {
                backgroundColor: "#0287d9",
            },
        },
    }))(Button);

    const handleCheckCardScheme = (value) => {
        if (value && value.length > 4) {
            value = value.substring(0, 4)
        }
        props.handleCardNumField(value)
    }

    useEffect(() => {
        if (payment.merchantCustomerInformation.billingAddress) {
            setInitialForm({
                ...initialForm,
                line1: '',
                line2: '',
                city: '',
                state: '',
                zipCode: '',
                country: 'PH'
            })
            setInitialValidation({
                ...initialValidation,
                line1: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                line2: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                city: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                state: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                country: Yup.string().required('You missed this! Fill in the required details to proceed.')
            })
        }

    }, [payment])

    useEffect(() => {
        dispatch(pgwTokenRequest({}, 'mpgs'))
    }, [])

    useEffect(() => {
        if (pgwData.sessionId) {
            setPgwSession(pgwData.sessionId)
        }
    }, [pgwData])

    useEffect(() => {
        if (isPanelSelected) {
            if (formRef.current) {
                formRef.current.resetForm(formRef.current.initialValues)
            }
        }
    }, [isPanelSelected])

    const handleFormOnChange = (values, isValid) => {
        dispatch(setFormValues(values, isValid))
    }

    const handleCardFieldMask = () => {
        setShowCardDetails(!showCardDetails)
    };

    const handleCvvFieldMask = () => {
        setShowCvvDetails(!showCvvDetails)
    };

    return (
        pgwSession === '' ? <><Loader variant={"indeterminate"} /></> :
            <div className={classes.root}>
                <Formik
                    initialValues={initialForm}
                    validationSchema={validation}
                    innerRef={formRef}
                >
                    {({
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                        values,
                        errors,
                        touched,
                        isValid
                    }) => (
                        <form action={`${process.env.REACT_APP_MPGS_FORM_URL}/${pgwSession}`} method='POST' enctype="application/x-www-form-urlencoded" onChange={() => handleFormOnChange(values, isValid)}>
                            <Grid
                                container
                                spacing={4}
                                id='grid-container-mpgs-form'
                            >
                                <Grid item xs={12} sm={12} md={12} id='grid-item-card-number'>
                                    <Field id='field-card-number' name='cardNumber'>
                                        {(fields) => (
                                            <InputMask
                                                id='input-mask-card-number'
                                                mask="9999 9999 9999 9999"
                                                onChange={handleChange('gatewayCardNumber')}
                                                onBlur={handleBlur('gatewayCardNumber')}
                                                // onInput={e => handleCheckCardScheme(e.target.value)}
                                                value={values.cardNumber}
                                                maskPlaceholder={""}
                                                disabled={disabled}
                                            >
                                                <TextField
                                                    id='text-field-card-number'
                                                    type={showCardDetails ? 'text' : 'password'}
                                                    variant="outlined"
                                                    name='gatewayCardNumber'
                                                    fullWidth
                                                    autoComplete='off'
                                                    placeholder="16-digit Card Number"
                                                    error={errors.gatewayCardNumber && touched.gatewayCardNumber && true}
                                                    helperText={errors.gatewayCardNumber && touched.gatewayCardNumber && errors.gatewayCardNumber}
                                                    InputProps={{
                                                        classes: {input: classes.cardFormFields},
                                                        endAdornment: (
                                                            <InputAdornment 
                                                                id='end-adornment-card-number'
                                                                position="end"
                                                            >
                                                                { props.cardLogo && showCardDetails ?
                                                                    <img src={props.cardLogo} className={classes.cardIcons} />
                                                                    : <></>
                                                                }
                                                                {props.cardLogo ? initialForm.isCardValid=true : initialForm.isCardValid=false}
                                                                <IconButton
                                                                    id='button-card-number-mask'
                                                                    size={'small'}
                                                                    onClick={handleCardFieldMask}
                                                                >
                                                                    {showCardDetails ? 
                                                                        <Visibility 
                                                                            id='button-show-card-number'
                                                                            className={classes.maskButton} 
                                                                        /> 
                                                                        : 
                                                                        <VisibilityOff 
                                                                            id='button-hide-card-number' 
                                                                            className={classes.maskButton} 
                                                                        />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </InputMask>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid container item xs={6} sm={6} md={6} >
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Field name='gatewayCardExpiryDateMonth'>
                                            {(fields) => (
                                                <InputMask
                                                    mask="99"
                                                    maskPlaceholder={null}
                                                    onChange={handleChange('gatewayCardExpiryDateMonth')}
                                                    onBlur={() => handleDateTouched(errors, setFieldTouched)}
                                                    value={values.gatewayCardExpiryDateMonth}
                                                    disabled={disabled}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        name='gatewayCardExpiryDateMonth'
                                                        fullWidth
                                                        autoComplete='off'
                                                        placeholder="Expiry Month"
                                                        error={errors.gatewayCardExpiryDateMonth && touched.gatewayCardExpiryDateMonth && true}
                                                        helperText={errors.gatewayCardExpiryDateMonth && touched.gatewayCardExpiryDateMonth && errors.gatewayCardExpiryDateMonth}
                                                        InputProps={{classes: {input: classes.cardFormFields}}}
                                                    />
                                                </InputMask>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} styles={{ paddingRight: 10, paddingLeft: 10 }}>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Field name='gatewayCardExpiryDateYear'>
                                            {(fields) => (
                                                <InputMask
                                                    mask="99"
                                                    maskPlaceholder={null}
                                                    onChange={e => handleDateChange(e, setFieldValue, setFieldError, values)}
                                                    onBlur={() => handleDateTouched(errors, setFieldTouched)}
                                                    value={values.gatewayCardExpiryDateYear}
                                                    disabled={disabled}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        name='gatewayCardExpiryDateYear'
                                                        fullWidth
                                                        autoComplete='off'
                                                        placeholder="Expiry Year"
                                                        error={errors.gatewayCardExpiryDateYear && touched.gatewayCardExpiryDateYear && true}
                                                        helperText={errors.gatewayCardExpiryDateYear && touched.gatewayCardExpiryDateYear && errors.gatewayCardExpiryDateYear}
                                                        InputProps={{classes: {input: classes.cardFormFields}}}
                                                    />
                                                </InputMask>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} id='grid-item-cvc'>
                                    <input name='gatewayReturnUrl' value={`${process.env.REACT_APP_BASE_URL}/redirect/?paymentSessionId=${pgwSession}&paymentId=${payment.paymentId}&methodId=${paymentProcessor.paymentMethodId}&selectedScheme=${paymentProcessor.paymentMethodName}`} hidden ></input>
                                    <Field id='field-cvc' name='cvc'>
                                        {(fields) => (
                                            <InputMask
                                                id='input-mask-cvc'
                                                mask="999"
                                                onChange={handleChange('gatewayCardSecurityCode')}
                                                onBlur={handleBlur('gatewayCardSecurityCode')}
                                                value={values.gatewayCardSecurityCode}
                                                maskPlaceholder={null}
                                                disabled={disabled}
                                            >
                                                <TextField
                                                    id='text-field-cvc'
                                                    type={showCvvDetails ? 'text' : 'password'}
                                                    variant="outlined"
                                                    name='gatewayCardSecurityCode'
                                                    fullWidth
                                                    placeholder="CVV/CVC"
                                                    autoComplete='off'
                                                    error={errors.gatewayCardSecurityCode && touched.gatewayCardSecurityCode}
                                                    helperText={errors.gatewayCardSecurityCode && touched.gatewayCardSecurityCode && errors.gatewayCardSecurityCode}
                                                    InputProps={{
                                                        classes: {input: classes.cardFormFields},
                                                        startAdornment: (
                                                            <InputAdornment 
                                                                id='start-adornment-cvc' 
                                                                position="start"
                                                            >
                                                                <Info
                                                                    id='info-cvc'
                                                                    color='initial'
                                                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                    aria-haspopup="true"
                                                                    onMouseEnter={handlePopoverOpen}
                                                                    onMouseLeave={handlePopoverClose}
                                                                    className={classes.popoverIcon}
                                                                ></Info>
                                                                <Popover
                                                                    id="mouse-over-popover"
                                                                    className={classes.popover}
                                                                    classes={{
                                                                        paper: classes.paper,
                                                                    }}
                                                                    open={open}
                                                                    anchorEl={anchorEl}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    onClose={handlePopoverClose}
                                                                    disableRestoreFocus
                                                                >
                                                                    <Typography id='typography-cvc' variant='caption'>You can find this 3 or 4 digit code at the back of your card, beside your signature.</Typography>
                                                                </Popover>
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment 
                                                                id='end-adornment-cvc' 
                                                                position="end"
                                                            >
                                                                <IconButton
                                                                    id='button-cvc-mask'
                                                                    size={'small'}
                                                                    onClick={handleCvvFieldMask}
                                                                >
                                                                    {showCvvDetails ?  
                                                                        <Visibility 
                                                                            id='button-show-cvc' 
                                                                            className={classes.maskButton} 
                                                                        /> 
                                                                        : 
                                                                        <VisibilityOff 
                                                                            id='button-hide-cvc' 
                                                                            className={classes.maskButton} 
                                                                        />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </InputMask>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Field
                                        name='firstName'
                                        variant="outlined"
                                        component={formikTextField}
                                        value={values.firstName}
                                        placeholder="First Name"
                                        fullWidth
                                        disabled={disabled}
                                        InputProps={{classes: {input: classes.cardFormFields}}}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Field
                                        name='lastName'
                                        variant="outlined"
                                        component={formikTextField}
                                        value={values.lastName}
                                        placeholder="Last Name"
                                        fullWidth
                                        disabled={disabled}
                                        InputProps={{classes: {input: classes.cardFormFields}}}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>
    );
}



export default MpgsForm;