export const paymentData = (state) => state.payment.data
export const paymentError = (state) => state.payment.error
export const pgwData = (state) => state.pgw.data
export const pgwError = (state) => state.pgw.error
export const paymentProcessor = (state) => state.payment.processor
export const bankFee = (state) => state.payment.processor.fee
export const isAuth = (state) => state.token.isAuth
export const token = (state) => state.token.accessToken
export const pgwTransaction = (state) => state.pgwData.transaction
export const pgwFormUpdate = (state) => state.pgwData.formUpdated
export const pgwSession = (state) => state.pgwData.sessionId
export const pgwPayData = (state) => state.pgwData.payData
export const processorData = (state) => state.payment.processor