import { CircularProgress } from '@material-ui/core';
import React from 'react';
import constant from '../../utils/Constants';
import Failed from './components/Failed/Failed';
import Success from './components/Success/Success';


const Status = (props) => {

    return (
        props.match.params.id === constant.status_success ? <Success /> : <Failed />
    );
}

export default Status;