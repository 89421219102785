import { all, delay, put, select, takeEvery, takeMaybe } from "@redux-saga/core/effects";
import processAction from "../../utils/ProcessActions";
import {
    paymayaToken as paymayaTokenService,
    createPaymaya as createPaymayaService,
    createSession as createSessionService,
    updateForm as updateFormService,
    initiateAuthentication as initiateAuthenticationService,
    authenticate as authenticateService,
    mpgsRedirect as mpgsRedirectService,
    mpgsPay as mpgsPayService
} from "./PgwServices";
import {
    PGW_MPGS_UPDATE_FORM,
    PGW_MPGS_UPDATE_FORM_FAILED,
    PGW_MPGS_UPDATE_FORM_SUCCESS,
    PGW_PAYMAYA_CREATE,
    PGW_PAYMAYA_CREATE_FAILED,
    PGW_PAYMAYA_CREATE_SUCCESS,
    PGW_TOKEN_FAILED,
    PGW_TOKEN_MPGS,
    PGW_TOKEN_PAYMAYA,
    PGW_TOKEN_SUCCESS,
    PGW_MPGS_INITIATE_AUTHENTICATION_FAILED,
    PGW_MPGS_INITIATE_AUTHENTICATION_SUCCESS,
    PGW_MPGS_INITIATE_AUTHENTICATION,
    PGW_MPGS_AUTHENTICATE,
    PGW_MPGS_AUTHENTICATE_SUCCESS,
    PGW_MPGS_AUTHENTICATE_FAILED,
    PGW_MPGS_REDIRECT_URL,
    PGW_MPGS_REDIRECT_URL_SUCCESS,
    PGW_MPGS_REDIRECT_URL_FAILED,
    PGW_MPGS_PAY,
    PGW_MPGS_PAY_SUCCESS,
    PGW_MPGS_PAY_FAILED,
    PGW_MPGS_CREATE_SESSION,
    PGW_MPGS_CREATE_SESSION_SUCCESS,
    PGW_MPGS_CREATE_SESSION_FAILED
} from "./PgwTypes";
import * as selectors from '../selectors'
import history from "../../utils/history";
import { CenterFocusStrongOutlined, CropLandscapeOutlined } from "@material-ui/icons";
import { REDIRECT_URL } from "../Auth/AuthTypes";

function* paymayaToken({ data }) {
    const paymentProcessor = yield (select(selectors.paymentProcessor))

    let requestData = {
        card: {
            number: data.number,
            expMonth: data.expMonth,
            expYear: data.expYear,
            cvc: data.cvc
        },
        link: paymentProcessor.link,
        publicKey: paymentProcessor.publicKey
    }

    // yield processAction({
    //     params: requestData,
    //     service: paymayaTokenService,
    //     success: PGW_TOKEN_SUCCESS,
    //     failed: PGW_TOKEN_FAILED
    // })

    const paymentData = yield (select(selectors.paymentData))
    const pgwToken = yield (select(selectors.pgwData))
    const bankFee = yield (select(selectors.bankFee))
    const processorData = yield (select(selectors.processorData))
    const pgwError = yield (select(selectors.pgwError))

    //if (pgwToken !== null) {
        const pgwRequest = {
            paymentId: paymentData.paymentId,
            methodId: processorData.paymentMethodId,
            card : requestData.card,
            data: {
                //paymentTokenId: pgwToken.paymentTokenId,
                buyer: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    billingAddress: data.billingAddress ? data.billingAddress : "",
                    isMobile: data.isMobile
                },
            }
        }

        if (data.hasOwnProperty('emailAddress')) {
            if (data.emailAddress)
                pgwRequest.data.buyer = {
                    ...pgwRequest.data.buyer,
                    emailAddress: data.emailAddress
                }
        }

        if (process.env.REACT_APP_ENVIRONMENT !== 'production') 
            console.log('PGW REQUEST DATA: ', pgwRequest);
        
        yield put({
            type: PGW_PAYMAYA_CREATE,
            data: pgwRequest
        })
    //} else if (pgwError.data.code === 'PY0036' || pgwError.data.code === 'PY0070') {
        //yield history.push('/redirect?q=' + process.env.REACT_APP_ROOT_URL + '/transaction/?alertMessage=Oops!%20Your%20card%20type%20and%20number%20do%20not%20match.%20Please%20try%20again.')
    //} else if (pgwError.data.code === '2553') {
        //yield history.push('/redirect?q=' + process.env.REACT_APP_ROOT_URL + '/transaction/?alertMessage=Oops!%20Missing/invalid%20card%20details%20parameters.%20Please%20try%20again.')
    //}
    //else {
        //yield history.push(`/status/failed`)
    //}
}

function* createPaymaya(data) {
    yield processAction({
        params: data,
        service: createPaymayaService,
        success: PGW_PAYMAYA_CREATE_SUCCESS,
        failed: PGW_PAYMAYA_CREATE_FAILED
    })

    const pgwData = yield (select(selectors.pgwData))
    const pgwError = yield (select(selectors.pgwError))

    if (pgwData && pgwData.verificationUrl) {
        yield put({
            type: REDIRECT_URL,
            allow: true
        })
        yield history.push(`/redirect?q=${pgwData.verificationUrl}`)
    } 
    
    if (pgwError.flag && pgwError.data.code !== 50005) {
        yield history.push(`/status/failed`)
    }
}

function* createSession({ data }) {
    let processorData = yield select(selectors.processorData)
    let paymentData = yield select(selectors.paymentData)
    yield processAction({
        params: {
            methodId: processorData.paymentMethodId,
            processorId: processorData.processorId,
            paymentId: paymentData.paymentId
        },
        service: createSessionService,
        success: PGW_MPGS_CREATE_SESSION_SUCCESS,
        failed: PGW_MPGS_CREATE_SESSION_FAILED
    })
}

function* updateForm({ data }) {

    let payment = yield select(selectors.paymentData)

    yield processAction({
        params: data,
        service: updateFormService,
        success: PGW_MPGS_UPDATE_FORM_SUCCESS,
        failed: PGW_MPGS_UPDATE_FORM_FAILED
    })

    let formUpdate = yield (select(selectors.pgwFormUpdate))
    let error = yield (select(selectors.pgwError))

    if (formUpdate) {
        yield put({
            type: PGW_MPGS_INITIATE_AUTHENTICATION,
            data: {
                ...data,
                paymentId: payment.paymentId
            }
        })
    } else if (error.flag) {
        yield history.push(`/status/failed`)
    }
}

function* initiateAuthentication({ data }) {

    let body = {
        data: {
            apiOperation: "INITIATE_AUTHENTICATION",
            order: {
                currency: "PHP"
            },
            session: {
                id: data.sessionId
            },
            authentication: {
                "acceptVersions": "3DS1,3DS2",
                "channel": "PAYER_BROWSER",
                "purpose": "PAYMENT_TRANSACTION"
            }
        },
        paymentId: data.paymentId
    }

    yield processAction({
        params: body,
        service: initiateAuthenticationService,
        success: PGW_MPGS_INITIATE_AUTHENTICATION_SUCCESS,
        failed: PGW_MPGS_INITIATE_AUTHENTICATION_FAILED
    })

    let pgwTransaction = yield select(selectors.pgwTransaction)
    let paymentData = yield select(selectors.paymentData)
    let error = yield select(selectors.pgwError)

    if (pgwTransaction) {
        if (pgwTransaction.result == "SUCCESS") {
            yield delay(2000);
            yield put({
                type: PGW_MPGS_AUTHENTICATE,
                data: pgwTransaction,
                payment: {
                    ...paymentData,
                    sessionId: data.sessionId
                },
            })
        } else {
            yield history.push(`/status/failed`)
        }
    } else if (error.flag) {
        yield history.push(`/status/failed`)
    }

}

function* authenticate({ data, payment }) {

    let processorData = yield select(selectors.processorData)
    let body = {
        data: {
            apiOperation: "AUTHENTICATE_PAYER",
            session: {
                id: payment.sessionId
            },
            device: {
                browser: "MOZILLA/4.0 (COMPATIBLE; MSIE 5.0; WINDOWS 95)",
                browserDetails: {
                    javaEnabled: true,
                    language: "en",
                    screenHeight: 600,
                    screenWidth: 800,
                    timeZone: "-600",
                    colorDepth: 24,
                    "3DSecureChallengeWindowSize": "FULL_SCREEN"
                }
            },
            authentication: {
                redirectResponseUrl: `${process.env.REACT_APP_BASE_URL}/redirect`
            }
        },
        paymentId: payment.paymentId,
        transactionId: data.transaction.id,
        orderId: data.order.id
    }

    yield processAction({
        params: body,
        service: authenticateService,
        success: PGW_MPGS_AUTHENTICATE_SUCCESS,
        failed: PGW_MPGS_AUTHENTICATE_FAILED
    })

    let pgwTransaction = yield select(selectors.pgwTransaction)
    let error = yield select(selectors.pgwError)

    if (pgwTransaction) {
        if (pgwTransaction.result === "PENDING" || pgwTransaction.result === "SUCCESS") {
            yield history.push(`/3ds`)
        } else {
            yield history.push(`/status/failed`)
        }
    } else if (error.flag) {
        yield history.push(`/status/failed`)
    }

}

function* mpgsRedirect({ data }) {
    yield processAction({
        params: data,
        service: mpgsRedirectService,
        success: PGW_MPGS_REDIRECT_URL_SUCCESS,
        failed: PGW_MPGS_REDIRECT_URL_FAILED
    })
}

function* mpgsPay() {
    let payment = yield select(selectors.paymentData)
    let transaction = yield select(selectors.pgwTransaction)
    let sessionId = yield select(selectors.pgwSession)
    let processorData = yield select(selectors.processorData)

    let body = {
        data: {
            apiOperation: "PAY",
            authentication: {
                transactionId: transaction.transaction.id
            },
            transaction: {
                reference: transaction.transaction.id
            },
            session: {
                id: sessionId
            },
            sourceOfFunds: {
                type: "CARD"
            }
        },
        paymentId: payment.paymentId,
        transactionId: transaction.transaction.id,

    }

    yield processAction({
        params: body,
        service: mpgsPayService,
        success: PGW_MPGS_PAY_SUCCESS,
        failed: PGW_MPGS_PAY_FAILED
    })

    let pgwPayData = yield select(selectors.pgwPayData)
    let error = yield select(selectors.pgwError)

    if (pgwPayData) {
        if (pgwPayData.result === "SUCCESS") {
            yield history.push(`/status/success`)
        } else {
            yield history.push(`/status/failed`)
        }
    } else if (error.flag) {
        yield history.push(`/status/failed`)
    }
}


function* failedToken() {
    yield history.push(`/status/failed`)
}

export default function* watchPgwRequests() {
    yield all([
        takeEvery(PGW_TOKEN_PAYMAYA, paymayaToken),
        takeEvery(PGW_PAYMAYA_CREATE, createPaymaya),
        takeEvery(PGW_MPGS_CREATE_SESSION, createSession),
        takeEvery(PGW_MPGS_UPDATE_FORM, updateForm),
        takeEvery(PGW_MPGS_INITIATE_AUTHENTICATION, initiateAuthentication),
        takeEvery(PGW_MPGS_AUTHENTICATE, authenticate),
        takeEvery(PGW_MPGS_REDIRECT_URL, mpgsRedirect),
        takeEvery(PGW_MPGS_PAY, mpgsPay),
        // takeEvery(PGW_TOKEN_FAILED, failedToken)
    ])
}